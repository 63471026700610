import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g fill="currentColor"><path clipRule="evenodd" d="M8 3a5 5 0 100 10A5 5 0 008 3zm0 1a4 4 0 100 8 4 4 0 000-8z" fillRule="evenodd" /><path d="M10.501 4.496l.724-.064-.13-1.494a.5.5 0 00-.543-.455l-.121.011-.226-1.631A1 1 0 009.215 0h-2.42a1 1 0 00-.99.863l-.227 1.631-.121-.01a.5.5 0 00-.542.454l-.13 1.494.723.064.126-1.44c.312.13 1.185.444 2.37.444s2.059-.313 2.371-.444zM5.503 11.5l-.723.063.13 1.495a.5.5 0 00.542.454l.121-.01.227 1.631a1 1 0 00.99.863h2.42a1 1 0 00.99-.863l.226-1.631.122.01a.5.5 0 00.541-.454l.13-1.495-.723-.063-.125 1.44A6.32 6.32 0 008 12.495a6.32 6.32 0 00-2.37.443zM12.125 5.409a.25.25 0 01.433-.25l.25.433a.25.25 0 01-.433.25zM12.375 10.159a.25.25 0 01.433.25l-.25.432a.25.25 0 11-.433-.25z" /></g></svg>;
});
const SvgMoto360 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgMoto360;