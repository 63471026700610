import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g clipRule="evenodd" fill="currentColor" fillRule="evenodd"><path d="M13.5 10.5a.5.5 0 01-.5-.5V6a.5.5 0 011 0v4a.5.5 0 01-.5.5z" /><path d="M1 5.5v5A1.5 1.5 0 002.5 12h11a1.5 1.5 0 001.5-1.5v-5A1.5 1.5 0 0013.5 4h-11A1.5 1.5 0 001 5.5zm-1 5A2.5 2.5 0 002.5 13h11a2.5 2.5 0 002.5-2.5v-5A2.5 2.5 0 0013.5 3h-11A2.5 2.5 0 000 5.5z" /></g></svg>;
});
const SvgLandscape = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgLandscape;