import {createReduxModule} from 'hooks-for-redux';

export interface Notification {
    id?: number,
    message?: string | React.ReactNode,
    autohide?: boolean,
    delay?: number // in seconds
}

type NotificationsState = Notification[]

const initialState: NotificationsState = []

let uniqueId = 0

export const [useNotification, {
    addNotification,
    dismissNotification
}] = createReduxModule('notifications', initialState, {
    addNotification: (state, {message, autohide = true, delay = 5}: Notification) => (
        [...state, {
            id: uniqueId++,
            message,
            autohide,
            delay
        }]
    ),
    dismissNotification: (state, id) => (state.filter((notification) => notification.id !== id)),
})
