// Based on: https://storybook.react-laag.com/?path=/docs/tooltip--page

import React from 'react'
import { useLayer, useHover, Arrow } from 'react-laag'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

interface Props {
  children: React.ReactElement
  value: string
}

const Tooltip: React.FC<Props> = (props) => {
  const { children, value } = props

  function isReactText(children: any) {
    return ['string', 'number'].includes(typeof children)
  }

  // Determine when to show tooltip and add a small delay on enter/leave
  const [isOver, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 })

  // Tooltip positioning
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    possiblePlacements: ['top-center', 'bottom-center'],
    auto: true,
    placement: 'bottom-center',
    triggerOffset: 8, // gap between content and tooltip
  })

  // When the trigger is not an element (e.g. selected text) it needs to be
  // wrapped in a span element in order to attach props.
  let trigger
  if (isReactText(children)) {
    trigger = (
      <span {...triggerProps} {...hoverProps}>
        {children}
      </span>
    )
  } else {
    trigger = React.cloneElement(children, {
      ...triggerProps,
      ...hoverProps,
    })
  }

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOver && (
            <StyledTooltip
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              {value}
              <Arrow
                {...arrowProps}
                angle={50}
                roundness={1}
                borderWidth={1}
                size={4}
                backgroundColor="inherit"
              />
            </StyledTooltip>
          )}
        </AnimatePresence>,
      )}
    </>
  )
}

const StyledTooltip = styled(motion.div)`
  ${({ theme }) => theme.type.UIRegular};
  ${({ theme }) => theme.elevation.depth2};
  color: ${({ theme }) => theme.color.textInverse};
  background-color: rgba(0, 0, 0, 0.95);
  border-radius: 8px;
  padding: 4px 8px;
  z-index: 1000;
`

export default Tooltip
