import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g fill="currentColor"><path clipRule="evenodd" d="M8 11.75a.75.75 0 01-.75-.75c0-.833.383-1.537.79-2.045a4.967 4.967 0 011.222-1.093A2.25 2.25 0 105.75 6a.75.75 0 01-1.5 0 3.75 3.75 0 115.804 3.137c-.21.126-.557.396-.843.754-.288.361-.461.742-.461 1.109a.75.75 0 01-.75.75z" fillRule="evenodd" /><path d="M9 14a1 1 0 11-2 0 1 1 0 012 0z" /></g></svg>;
});
const SvgQuestionMark = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgQuestionMark;