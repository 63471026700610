import { createReduxModule } from 'hooks-for-redux'
import Device from '../entities/Device'
import { devices } from '../assets/devices'
import DeviceNode, { IDeviceNodeProps } from '../nodes/DeviceNode'

//todo remove after node-refactor
export enum DeviceOrientation {
  Portrait,
  Landscape,
}

export interface IStage {
  zoomLevel?: number
  fitZoomLevel?: number
  nodes?: Array<DeviceNode>
  activeNode?: DeviceNode
  rerenderCycle?: number
}

const defaultDevice = new Device(
  devices.find((device) => device.id === 'iphone14pro') ?? devices[0],
)

const defaultNodeProps: IDeviceNodeProps = {
  device: defaultDevice,
  variant: defaultDevice.getVariants()[0],
}

const initialState: IStage = {
  zoomLevel: 100,
  nodes: [new DeviceNode(defaultNodeProps)],
  rerenderCycle: 1,
}

export const [useStageState, { setStage, setStageAndSetRerender, clearStage }] =
  createReduxModule('stage', initialState, {
    setStage: (state, value: IStage) => ({ ...state, ...value }),
    setStageAndSetRerender: (state, value: IStage) => ({
      ...value,
      ...state,
      rerenderCycle: state.rerenderCycle ? state.rerenderCycle + 1 : 1,
    }),
    clearStage: () => initialState,
  })
