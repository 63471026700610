import Device from '../entities/Device'
import {DeviceOrientation, setStageAndSetRerender} from '../state/stage.state'
import DeviceVariant from '../entities/DeviceVariant'
import DeviceNode from '../nodes/DeviceNode'

export function setActiveDevice(activeNode: DeviceNode, device: Device, deviceVariant?: DeviceVariant) {
    if (activeNode?.attributes?.device === undefined) {
        return
    }

    const doResetCropValues = JSON.stringify(activeNode.attributes?.variant?.getDesignPositions()) !== JSON.stringify(deviceVariant?.getDesignPositions())

    if (doResetCropValues) {
        activeNode.resetCropValues()
    }

    activeNode.attributes.device = device
    activeNode.attributes.variant = deviceVariant ?? device.getVariants()[0]
    activeNode.attributes.deviceFileIsLoading = true


    if (false === activeNode.attributes.variant.hasDifferentOrientations()) {
        if (activeNode.attributes.deviceOrientation !== DeviceOrientation.Portrait) {
            activeNode.attributes.deviceOrientation = DeviceOrientation.Portrait
            activeNode.resetCropValues()
        }
    }

    setStageAndSetRerender({activeNode: activeNode})
}
