import { useRef, useState } from 'react'
import styled from 'styled-components'
import { initialState, setDocument, useDocumentState } from '../state/document.state'

const DocumentName: React.FC = () => {
  const documentName = useDocumentState().name
  const [documentNameValue, setDocumentNameValue] = useState(documentName)

  const nameInputRef = useRef<HTMLInputElement>(null)

  const cancelNameChange = (event: any) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.preventDefault()
      // Set input value back to saved document named
      setDocumentNameValue(documentName)

      // @ts-ignore
      window.dataLayer.push({
        event: 'Document Name Update Cancelled',
        eventProps: {},
      })
    }
  }

  const submitNameChange = (event: React.FormEvent) => {
    // Prevent page refresh on submit
    event.preventDefault()

    // Remove focus from input after submitting (e.g. with enter)
    nameInputRef.current && nameInputRef.current.blur()

    //Check for empty value
    //todo add better empty-value-checking
    if (documentNameValue === '') {
      setDocumentNameValue(initialState.name)
    }

    // Pass input value to state
    setDocument({ name: documentNameValue })

    // @ts-ignore
    window.dataLayer.push({
      event: 'Document Name Changed',
      eventProps: {},
    })
  }

  return (
    <form onSubmit={(e) => submitNameChange(e)}>
      <FileName
        ref={nameInputRef}
        type='text'
        value={documentNameValue}
        onChange={(e) => setDocumentNameValue(e.target.value)}
        onFocus={(e) => e.target.select()}
        onBlur={(e) => submitNameChange(e)}
        onKeyDown={(e) => cancelNameChange(e)}
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        spellCheck='false'
      />
    </form>
  )
}

const FileName = styled.input`
  ${({ theme }) => theme.type.label}
  color: ${({ theme }) => theme.color.text3};
  outline: 0;
  border: 0;
  transition: all 0.2s ease-in-out;
  width: 200px;
  &:focus {
    color: ${({ theme }) => theme.color.text2};
    transition: none;
  }
`

export default DocumentName
