import React, { useState } from 'react'
import styled from 'styled-components'
import { devices, IDeviceData } from '../assets/devices'
import PopOver from './PopOver'
import Stack from './Stack'
import theme from '../styles/defaultTheme'
import Device from '../entities/Device'
import { useStageState } from '../state/stage.state'
import { setActiveDevice } from '../helpers/DeviceStateSetter'
import Icon from './icons'

interface Props {
  children: React.ReactElement
  activeDevice: Device | undefined
}

const DevicePopOver: React.FC<Props> = (props) => {
  const { children, activeDevice } = props

  const [showAllDevices, setShowAllDevices] = useState(false)

  const stageState = useStageState()

  function getDevicesInCategory(
    devices: IDeviceData[],
    category: string,
  ): Array<Device> {
    const devicesInCategory = devices
      .filter((device) => device.category === category)
      .map((deviceData) => new Device(deviceData))

    if (!showAllDevices) {
      return devicesInCategory.filter((device) => !device.data.archived)
    } else {
      return devicesInCategory
    }
  }

  // Order of categories to show
  const categories = [
    {
      label: 'Phones',
      id: 'phones',
    },
    {
      label: 'AI Devices',
      id: 'ai-devices',
    },
    {
      label: 'Tablets',
      id: 'tablets',
    },
    {
      label: 'Watches',
      id: 'watches',
    },
    {
      label: 'Laptops',
      id: 'laptops',
    },
    {
      label: 'TVs',
      id: 'tvs',
    },
    {
      label: 'Desktops',
      id: 'desktops',
    },
    {
      label: 'Displays',
      id: 'displays',
    },
  ]

  const contents = (
    <StyledPopOver>
      <PanelHeader gap={8}>
        <div className="panel-title">Devices</div>
        <TextButton onClick={() => setShowAllDevices(!showAllDevices)}>
          {showAllDevices ? 'Show less' : 'Show more'}
        </TextButton>
        <button className="close-button" data-closepopover>
          <Icon name="Close" size={16} />
        </button>
      </PanelHeader>
      <PanelContent>
        {categories.map((category) => (
          <Category key={category.id}>
            <CategoryName>{category.label}</CategoryName>
            {getDevicesInCategory(devices, category.id).map((device) => (
              <DeviceItem
                data-closepopover
                key={device.data.id}
                onClick={() =>
                  stageState.activeNode &&
                  setActiveDevice(stageState.activeNode, device)
                }
                id={
                  device.data.id === activeDevice?.data.id
                    ? 'active'
                    : undefined
                }
                active={device.data.id === activeDevice?.data.id}
              >
                <Stack gap={8} align="center">
                  <div className="icon-frame">
                    <Icon
                      name={device.data.iconName}
                      size={16}
                      color={
                        device.data.id === activeDevice?.data.id
                          ? theme.color.textInverse
                          : theme.color.text1
                      }
                    />
                  </div>
                  <Stack distribute="spaceBetween">
                    <div className="device-name">{device.data.name}</div>
                    {device.data.viewport && (
                      <DeviceViewport>{`${device.data.viewport.width}x${device.data.viewport.height}`}</DeviceViewport>
                    )}
                  </Stack>
                </Stack>
              </DeviceItem>
            ))}
          </Category>
        ))}
      </PanelContent>
    </StyledPopOver>
  )

  return (
    <PopOver
      contents={contents}
      width={296}
      placement="left-start"
      autoPlacement={false}
      hideArrow
      initialFocus={
        activeDevice && !activeDevice.data.archived
          ? '#active'
          : '#popOverContainer'
      }
    >
      {children}
    </PopOver>
  )
}

const StyledPopOver = styled.div`
  padding: 0px;
  background: ${({ theme }) => theme.color.glass};
  border: 1px solid ${({ theme }) => theme.color.border};
  backdrop-filter: blur(10px);
  overflow: hidden;
  border-radius: 8px;
`
const PanelHeader = styled(Stack)`
  ${({ theme }) => theme.type.UIHeading};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding: 12px 8px 8px 16px;
  position: absolute;
  top: 0;
  background: ${({ theme }) => theme.color.glass};
  align-items: center;
  .panel-title {
    flex: 1;
  }
  .close-button {
    display: block;
    outline: 0;
    cursor: pointer;
    padding: 4px;
    border-radius: 8px;
    fill: ${({ theme }) => theme.color.text2};
    transition: all 0.2s ease-in-out;
    &:hover,
    &:focus {
      fill: ${({ theme }) => theme.color.text1};
      background: ${({ theme }) => theme.color.background3};
    }
  }
`

const TextButton = styled.button`
  outline: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.color.text3};
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.actionDark};
  }
`

const PanelContent = styled.div`
  margin-top: 39px;
  padding: 12px 8px 0 8px;
  max-height: 400px;
  overflow-y: scroll;
  /* Hide scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Category = styled.div`
  margin-bottom: 12px;
`

const CategoryName = styled.div`
  ${({ theme }) => theme.type.UIHeading};
  color: ${({ theme }) => theme.color.text3};
  margin-bottom: 4px;
  padding: 0 8px;
`

const DeviceItem = styled.button<{ active: boolean }>`
  margin-bottom: 2px;
  ${({ theme }) => theme.type.UIRegular};
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 4px 12px;
  width: 100%;
  text-align: left;
  outline: 0;
  border-radius: 8px;
  color: ${({ theme }) => theme.color.text2};
  &:hover,
  &:focus {
    background-color: rgb(0 0 0 / 10%);
    color: ${({ theme }) => theme.color.text1};
  }
  .icon-frame {
    transition: all 0.15s ease-in-out;
    padding: ${(props) => (props.active ? '6px' : '4px')};
    background-color: ${(props) =>
      props.active ? theme.color.action : 'transparent'};
    border-radius: 6px;
  }
  .device-name {
    flex: 1;
  }
`

const DeviceViewport = styled.div`
  ${({ theme }) => theme.type.UISmall};
  color: ${({ theme }) => theme.color.text3};
  text-align: right;
`

export default DevicePopOver
