import styled from 'styled-components'
import theme from '../styles/defaultTheme'

// Infer variants from defaultTheme (theme.type)
interface Props {
  as?: any
  textColor?: any
  variant: keyof typeof theme.type
}

const Type: React.FC<Props> = ({ as, children, textColor, variant }) => {
  return (
    <StyledText
      as={as}
      variant={variant}
      children={children}
      textColor={textColor}
    />
  )
}

const StyledText = styled.div<{
  variant: keyof typeof theme.type
  textColor: string
}>`
  ${(props) => props.theme.type[props.variant]};
  color: ${(props) => (props.textColor ? props.textColor : 'inherit')};
`

export default Type
