import React from 'react'
import styled from 'styled-components'
import Stack from './Stack'
import DownloadButton from './DownloadButton'
import MainMenu from './MainMenu'
import DocumentName from './DocumentName'
import {motion} from 'framer-motion'
import Icon from './icons'
import Type from './Type'

const Header: React.FC = () => {
    return (
        <StyledHeader>
            <Stack distribute="spaceBetween" align="center">
                <div>
                    <Stack align="center">
                        <MainMenu>
                            <MenuButton whileTap={{scale: 0.9}}>
                                <Icon name="Menu"/>
                            </MenuButton>
                        </MainMenu>
                        <DocumentName/>
                    </Stack>
                </div>
                <div>
                    <Stack gap={25} align="center">
                        <Type
                            variant="UISmall">
                            <TextButton data-az-l="3f08f383-2ad5-427f-a19a-b3fcc0249342" className="feedback-button" id="properties-panel-feedback-button">
                                Give feedback
                            </TextButton>
                        </Type>
                        <DownloadButton/>
                    </Stack>
                </div>
            </Stack>
        </StyledHeader>
    )
}

const StyledHeader = styled.div`
  background: ${({theme}) => theme.color.background1};
  border-bottom: 1px solid ${({theme}) => theme.color.background2};
  padding: 16px 24px;
  width: 100%;
`

const MenuButton = styled(motion.button)`
  padding: 12px;
  background-color: ${({theme}) => theme.color.background2};
  border-radius: 50%;
  cursor: pointer;
  outline: 0;
  transition: all 0.2s ease-in-out;

  & svg {
    display: block;
    fill: ${({theme}) => theme.color.text2};
    transition: all 0.2s ease-in-out;
  }

  &:hover,
  &:focus {
    background-color: ${({theme}) => theme.color.background3};

    & svg {
      fill: ${({theme}) => theme.color.text1};
    }
  }
`

const TextButton = styled.button`
  outline: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.3rem;

  &:hover,
  &:focus {
    color: ${({theme}) => theme.color.actionDark};
  }
`

export default Header
