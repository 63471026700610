import { IconNames } from '../components/icons/template/IconType'

export interface IDeviceData {
  id: string
  name: string
  hasDifferentOrientations?: boolean
  iconName: IconNames
  category?: string
  archived?: boolean
  screen_positions?: {
    x: number
    y: number
    width: number
    height: number
  }
  viewport?: {
    width?: number
    height?: number
  }
  variants: IDeviceVariantData[]
  variantsHasLabels?: boolean
  alert?: string
}

export interface IDeviceVariantData {
  id: string
  title: string
  hasDifferentOrientations?: boolean
  mockStageFile: string
  maskFile?: string
  button_color?: string
  screen_positions?: {
    x: number
    y: number
    width: number
    height: number
  }
  viewport?: {
    width?: number
    height?: number
  }
  alert?: string
}

export const devices: IDeviceData[] = [
  {
    id: 'iphone-16',
    name: 'iPhone 16',
    iconName: 'IPhone14',
    category: 'phones',
    screen_positions: {
      x: 67,
      y: 59,
      width: 1179,
      height: 2556,
    },
    viewport: {
      width: 393,
      height: 852,
    },
    variants: [
      {
        id: 'ultramarine',
        title: 'Ultramarine',
        mockStageFile: 'iPhone16/iPhone-16-ultramarine.png',
        maskFile: 'iPhone16/mask.png',
        button_color: '#A9B6F1',
      },
      {
        id: 'teal',
        title: 'Teal',
        mockStageFile: 'iPhone16/iPhone-16-teal.png',
        maskFile: 'iPhone16/mask.png',
        button_color: '#BFD7D5',
      },
      {
        id: 'pink',
        title: 'Pink',
        mockStageFile: 'iPhone16/iPhone-16-pink.png',
        maskFile: 'iPhone16/mask.png',
        button_color: '#EAB6DA',
      },
      {
        id: 'white',
        title: 'White',
        mockStageFile: 'iPhone16/iPhone-16-white.png',
        maskFile: 'iPhone16/mask.png',
        button_color: '#FAFBFA',
      },
      {
        id: 'black',
        title: 'Black',
        mockStageFile: 'iPhone16/iPhone-16-black.png',
        maskFile: 'iPhone16/mask.png',
        button_color: '#4E5153',
      },
    ],
  },
  {
    id: 'iphone-16-pro',
    name: 'iPhone 16 Pro',
    iconName: 'IPhone14',
    category: 'phones',
    screen_positions: {
      x: 50,
      y: 44,
      width: 1206,
      height: 2622,
    },
    viewport: {
      width: 402,
      height: 874,
    },
    variants: [
      {
        id: 'desert-titanium',
        title: 'Desert Titanium',
        mockStageFile: 'iPhone16Pro/iPhone-16-pro-desert-titanium.png',
        maskFile: 'iPhone16Pro/mask.png',
        button_color: '#C0AC99',
      },
      {
        id: 'natural-titanium',
        title: 'Natural Titanium',
        mockStageFile: 'iPhone16Pro/iPhone-16-pro-natural-titanium.png',
        maskFile: 'iPhone16Pro/mask.png',
        button_color: '#C6C2BB',
      },
      {
        id: 'white-titanium',
        title: 'White Titanium',
        mockStageFile: 'iPhone16Pro/iPhone-16-pro-white-titanium.png',
        maskFile: 'iPhone16Pro/mask.png',
        button_color: '#F2F1EE',
      },
      {
        id: 'black-titanium',
        title: 'Black Titanium',
        mockStageFile: 'iPhone16Pro/iPhone-16-pro-black-titanium.png',
        maskFile: 'iPhone16Pro/mask.png',
        button_color: '#4D4E4D',
      },
    ],
  },
  {
    id: 'iphone-16-pro-max',
    name: 'iPhone 16 Pro Max',
    iconName: 'IPhone14',
    category: 'phones',
    screen_positions: {
      x: 52,
      y: 44,
      width: 1320,
      height: 2868,
    },
    viewport: {
      width: 440,
      height: 956,
    },
    variants: [
      {
        id: 'desert-titanium',
        title: 'Desert Titanium',
        mockStageFile: 'iPhone16ProMax/iPhone-16-pro-max-desert-titanium.png',
        maskFile: 'iPhone16ProMax/mask.png',
        button_color: '#C0AC99',
      },
      {
        id: 'natural-titanium',
        title: 'Natural Titanium',
        mockStageFile: 'iPhone16ProMax/iPhone-16-pro-max-natural-titanium.png',
        maskFile: 'iPhone16ProMax/mask.png',
        button_color: '#C6C2BB',
      },
      {
        id: 'white-titanium',
        title: 'White Titanium',
        mockStageFile: 'iPhone16ProMax/iPhone-16-pro-max-white-titanium.png',
        maskFile: 'iPhone16ProMax/mask.png',
        button_color: '#F2F1EE',
      },
      {
        id: 'black-titanium',
        title: 'Black Titanium',
        mockStageFile: 'iPhone16ProMax/iPhone-16-pro-max-black-titanium.png',
        maskFile: 'iPhone16ProMax/mask.png',
        button_color: '#4D4E4D',
      },
    ],
  },
  {
    id: 'iphone-14',
    name: 'iPhone 14',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 65,
      y: 60,
      width: 1180,
      height: 2542,
    },
    viewport: {
      width: 390,
      height: 844,
    },
    variants: [
      {
        id: 'midnight',
        title: 'Midnight',
        mockStageFile: 'iPhone14/iPhone-14-midnight.png',
        maskFile: 'iPhone14/mask.png',
        button_color: '#374043',
      },
      {
        id: 'starlight',
        title: 'Starlight',
        mockStageFile: 'iPhone14/iPhone-14-starlight.png',
        maskFile: 'iPhone14/mask.png',
        button_color: '#FAF8F2',
      },
      {
        id: 'blue',
        title: 'Blue',
        mockStageFile: 'iPhone14/iPhone-14-blue.png',
        maskFile: 'iPhone14/mask.png',
        button_color: '#AABCCB',
      },
      {
        id: 'purple',
        title: 'Purple',
        mockStageFile: 'iPhone14/iPhone-14-purple.png',
        maskFile: 'iPhone14/mask.png',
        button_color: '#E4DDEA',
      },
      {
        id: 'productRed',
        title: '(PRODUCT)RED',
        mockStageFile: 'iPhone14/iPhone-14-product-red.png',
        maskFile: 'iPhone14/mask.png',
        button_color: '#FB102F',
      },
    ],
  },
  {
    id: 'iphone-14-pro',
    name: 'iPhone 14 Pro',
    iconName: 'IPhone14',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 68,
      y: 58,
      width: 1179,
      height: 2556,
    },
    viewport: {
      width: 393,
      height: 852,
    },
    variants: [
      {
        id: 'spaceblack',
        title: 'Space Black',
        mockStageFile: 'iPhone14Pro/iPhone-14-pro-space-black.png',
        maskFile: 'iPhone14Pro/mask.png',
        button_color: '#4C4B49',
      },
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iPhone14Pro/iPhone-14-pro-silver.png',
        maskFile: 'iPhone14Pro/mask.png',
        button_color: '#F1F4F3',
      },
      {
        id: 'gold',
        title: 'Gold',
        mockStageFile: 'iPhone14Pro/iPhone-14-pro-gold.png',
        maskFile: 'iPhone14Pro/mask.png',
        button_color: '#F5E8CE',
      },
      {
        id: 'deeppurple',
        title: 'Deep Purple',
        mockStageFile: 'iPhone14Pro/iPhone-14-pro-deep-purple.png',
        maskFile: 'iPhone14Pro/mask.png',
        button_color: '#61586B',
      },
    ],
  },
  {
    id: 'iphone-14-pro-max',
    name: 'iPhone 14 Pro Max',
    iconName: 'IPhone14',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 67,
      y: 57,
      width: 1290,
      height: 2796,
    },
    viewport: {
      width: 428,
      height: 928,
    },
    variants: [
      {
        id: 'spaceblack',
        title: 'Space Black',
        mockStageFile: 'iPhone14ProMax/iPhone-14-pro-max-space-black.png',
        maskFile: 'iPhone14ProMax/mask.png',
        button_color: '#4C4B49',
      },
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iPhone14ProMax/iPhone-14-pro-max-silver.png',
        maskFile: 'iPhone14ProMax/mask.png',
        button_color: '#F1F4F3',
      },
      {
        id: 'gold',
        title: 'Gold',
        mockStageFile: 'iPhone14ProMax/iPhone-14-pro-max-gold.png',
        maskFile: 'iPhone14ProMax/mask.png',
        button_color: '#F5E8CE',
      },
      {
        id: 'deeppurple',
        title: 'Deep Purple',
        mockStageFile: 'iPhone14ProMax/iPhone-14-pro-max-deep-purple.png',
        maskFile: 'iPhone14ProMax/mask.png',
        button_color: '#61586B',
      },
    ],
  },
  {
    id: 'iphone14concept',
    name: 'iPhone 14 Concept',
    iconName: 'IPhone14Concept',
    category: 'phones',
    variantsHasLabels: true,
    archived: true,
    alert:
      'This device is a concept, solely based on rumors. Read more on our <a href="https://www.mockmagic.com/blog/iphone-14-rumors" target="_blank">blog</a>.',
    screen_positions: {
      x: 65,
      y: 60,
      width: 1180,
      height: 2542,
    },
    viewport: {
      width: 390,
      height: 844,
    },
    variants: [
      {
        id: 'hole-and-pill-cutout',
        title: 'Pill + circular cutout',
        mockStageFile: 'iPhone14Concept/iPhone-14-concept-hole-and-pill.png',
        maskFile: 'iPhone14Concept/mask.png',
      },
      {
        id: 'circular-cutout',
        title: 'Circular cutout',
        mockStageFile: 'iPhone14Concept/iPhone-14-concept-circular.png',
        maskFile: 'iPhone14Concept/mask.png',
      },
    ],
  },
  {
    id: 'iphone13',
    name: 'iPhone 13',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 70,
      y: 60,
      width: 1173,
      height: 2532,
    },
    viewport: {
      width: 390,
      height: 844,
    },
    variants: [
      {
        id: 'midnight',
        title: 'Midnight',
        mockStageFile: 'iPhone13/iPhone-13-midnight.png',
        maskFile: 'iPhone13/mask.png',
        button_color: '#41474D',
      },
      {
        id: 'starlight',
        title: 'Starlight',
        mockStageFile: 'iPhone13/iPhone-13-starlight.png',
        maskFile: 'iPhone13/mask.png',
        button_color: '#FBF7F4',
      },
      {
        id: 'blue',
        title: 'Blue',
        mockStageFile: 'iPhone13/iPhone-13-blue.png',
        maskFile: 'iPhone13/mask.png',
        button_color: '#447791',
      },
      {
        id: 'pink',
        title: 'Pink',
        mockStageFile: 'iPhone13/iPhone-13-pink.png',
        maskFile: 'iPhone13/mask.png',
        button_color: '#FBE1DD',
      },
      {
        id: 'productRed',
        title: '(PRODUCT)RED',
        mockStageFile: 'iPhone13/iPhone-13-product-red.png',
        maskFile: 'iPhone13/mask.png',
        button_color: '#C82132',
      },
    ],
  },
  {
    id: 'iphone13mini',
    name: 'iPhone 13 mini',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 70,
      y: 62,
      width: 1080,
      height: 2340,
    },
    viewport: {
      width: 375,
      height: 812,
    },
    variants: [
      {
        id: 'midnight',
        title: 'Midnight',
        mockStageFile: 'iPhone13Mini/iphone-13-mini-midnight.png',
        maskFile: 'iPhone13Mini/mask.png',
        button_color: '#41474D',
      },
      {
        id: 'starlight',
        title: 'Starlight',
        mockStageFile: 'iPhone13Mini/iphone-13-mini-starlight.png',
        maskFile: 'iPhone13Mini/mask.png',
        button_color: '#FBF7F4',
      },
      {
        id: 'blue',
        title: 'Blue',
        mockStageFile: 'iPhone13Mini/iphone-13-mini-blue.png',
        maskFile: 'iPhone13Mini/mask.png',
        button_color: '#447791',
      },
      {
        id: 'pink',
        title: 'Pink',
        mockStageFile: 'iPhone13Mini/iphone-13-mini-pink.png',
        maskFile: 'iPhone13Mini/mask.png',
        button_color: '#FBE1DD',
      },
      {
        id: 'productRed',
        title: '(PRODUCT)RED',
        mockStageFile: 'iPhone13Mini/iphone-13-mini-product-red.png',
        maskFile: 'iPhone13Mini/mask.png',
        button_color: '#C82132',
      },
    ],
  },
  {
    id: 'iphone12',
    name: 'iPhone 12',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 70,
      y: 60,
      width: 1170,
      height: 2532,
    },
    viewport: {
      width: 375,
      height: 812,
    },
    variants: [
      {
        id: 'black',
        title: 'Black',
        mockStageFile: 'iPhone12/Apple iPhone 12 Black.png',
        maskFile: 'iPhone12/mask-white.png',
        button_color: '#2A292F',
      },
      {
        id: 'white',
        title: 'White',
        mockStageFile: 'iPhone12/Apple iPhone 12 White.png',
        maskFile: 'iPhone12/mask-white.png',
        button_color: '#E8E7E6',
      },
      {
        id: 'blue',
        title: 'Blue',
        mockStageFile: 'iPhone12/Apple iPhone 12 Blue.png',
        maskFile: 'iPhone12/mask-white.png',
        button_color: '#344E59',
      },
      {
        id: 'green',
        title: 'Green',
        mockStageFile: 'iPhone12/Apple iPhone 12 Green.png',
        maskFile: 'iPhone12/mask-white.png',
        button_color: '#D3EFD1',
      },
      {
        id: 'red',
        title: '(PRODUCT)RED',
        mockStageFile: 'iPhone12/Apple iPhone 12 Red.png',
        maskFile: 'iPhone12/mask-white.png',
        button_color: '#CF2A32',
      },
    ],
  },
  {
    id: 'iphone12mini',
    name: 'iPhone 12 mini',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 69,
      y: 58,
      width: 1080,
      height: 2340,
    },
    viewport: {
      width: 360,
      height: 780,
    },
    variants: [
      {
        id: 'black',
        title: 'Black',
        mockStageFile: 'iPhone12Mini/Apple iPhone 12 Mini Black.png',
        maskFile: 'iPhone12Mini/mask-white.png',
        button_color: '#2A292F',
      },
      {
        id: 'white',
        title: 'White',
        mockStageFile: 'iPhone12Mini/Apple iPhone 12 Mini White.png',
        maskFile: 'iPhone12Mini/mask-white.png',
        button_color: '#E8E7E6',
      },
      {
        id: 'blue',
        title: 'Blue',
        mockStageFile: 'iPhone12Mini/Apple iPhone 12 Mini Blue.png',
        maskFile: 'iPhone12Mini/mask-white.png',
        button_color: '#344E59',
      },
      {
        id: 'green',
        title: 'Green',
        mockStageFile: 'iPhone12Mini/Apple iPhone 12 Mini Green.png',
        maskFile: 'iPhone12Mini/mask-white.png',
        button_color: '#D3EFD1',
      },
      {
        id: 'red',
        title: '(PRODUCT)RED',
        mockStageFile: 'iPhone12Mini/Apple iPhone 12 Mini Red.png',
        maskFile: 'iPhone12Mini/mask-white.png',
        button_color: '#CF2A32',
      },
    ],
  },
  {
    id: 'iphone12pro',
    name: 'iPhone 12 Pro',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 70,
      y: 60,
      width: 1170,
      height: 2532,
    },
    viewport: {
      width: 390,
      height: 844,
    },
    variants: [
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iPhone12Pro/Apple iPhone 12 Pro Silver.png',
        maskFile: 'iPhone12Pro/mask-white.png',
        button_color: '#E0E1DB',
      },
      {
        id: 'gold',
        title: 'Gold',
        mockStageFile: 'iPhone12Pro/Apple iPhone 12 Pro Gold.png',
        maskFile: 'iPhone12Pro/mask-white.png',
        button_color: '#DAD4C0',
      },
      {
        id: 'graphite',
        title: 'Graphite',
        mockStageFile: 'iPhone12Pro/Apple iPhone 12 Pro Graphite.png',
        maskFile: 'iPhone12Pro/mask-white.png',
        button_color: '#4F4E4B',
      },
      {
        id: 'blue',
        title: 'Pacific Blue',
        mockStageFile: 'iPhone12Pro/Apple iPhone 12 Pro Pacific Blue.png',
        maskFile: 'iPhone12Pro/mask-white.png',
        button_color: '#344E59',
      },
    ],
  },
  {
    id: 'iphone12promax',
    name: 'iPhone 12 Pro Max',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 73,
      y: 60,
      width: 1284,
      height: 2778,
    },
    viewport: {
      width: 428,
      height: 926,
    },
    variants: [
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iPhone12ProMax/Apple iPhone 12 Pro Max Silver.png',
        maskFile: 'iPhone12ProMax/mask-white.png',
        button_color: '#E0E1DB',
      },
      {
        id: 'gold',
        title: 'Gold',
        mockStageFile: 'iPhone12ProMax/Apple iPhone 12 Pro Max Gold.png',
        maskFile: 'iPhone12ProMax/mask-white.png',
        button_color: '#DAD4C0',
      },
      {
        id: 'graphite',
        title: 'Graphite',
        mockStageFile: 'iPhone12ProMax/Apple iPhone 12 Pro Max Graphite.png',
        maskFile: 'iPhone12ProMax/mask-white.png',
        button_color: '#4F4E4B',
      },
      {
        id: 'blue',
        title: 'Pacific Blue',
        mockStageFile:
          'iPhone12ProMax/Apple iPhone 12 Pro Max Pacific Blue.png',
        maskFile: 'iPhone12ProMax/mask-white.png',
        button_color: '#344E59',
      },
    ],
  },
  {
    id: 'iphone11',
    name: 'iPhone 11',
    iconName: 'IPhoneX111212Mini',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 100,
      y: 100,
      width: 828,
      height: 1792,
    },
    viewport: {
      width: 414,
      height: 896,
    },
    variants: [
      {
        id: 'black',
        title: 'Black',
        mockStageFile: 'iPhone11/Apple iPhone 11 Black.png',
        button_color: '#181918',
      },
      {
        id: 'green',
        title: 'Green',
        mockStageFile: 'iPhone11/Apple iPhone 11 Green.png',
        button_color: '#9FDAC2',
      },
      {
        id: 'purple',
        title: 'Purple',
        mockStageFile: 'iPhone11/Apple iPhone 11 Purple.png',
        button_color: '#C6C1D2',
      },
      {
        id: 'red',
        title: '(PRODUCT)RED',
        mockStageFile: 'iPhone11/Apple iPhone 11 Red.png',
        button_color: '#A90024',
      },
      {
        id: 'white',
        title: 'White',
        mockStageFile: 'iPhone11/Apple iPhone 11 White.png',
        button_color: '#F7F4EB',
      },
      {
        id: 'yellow',
        title: 'Yellow',
        mockStageFile: 'iPhone11/Apple iPhone 11 Yellow.png',
        button_color: '#FEE26E',
      },
    ],
  },
  {
    id: 'iphone8',
    name: 'iPhone 8',
    iconName: 'IPhone58',
    category: 'phones',
    archived: true,
    screen_positions: {
      x: 100,
      y: 280,
      width: 749,
      height: 1334,
    },
    viewport: {
      width: 375,
      height: 667,
    },
    variants: [
      {
        id: 'gray',
        title: 'Space gray',
        mockStageFile: 'iPhone8/Apple iPhone 8 Space Grey.png',
        button_color: '#69696E',
      },
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iPhone8/Apple iPhone 8 Silver.png',
        button_color: '#D6D6D7',
      },
      {
        id: 'gold',
        title: 'Gold',
        mockStageFile: 'iPhone8/Apple iPhone 8 Gold.png',
        button_color: '#E5CDBC',
      },
    ],
  },
  {
    id: 'iphone5s',
    name: 'iPhone 5s',
    iconName: 'IPhone58',
    category: 'phones',
    screen_positions: {
      x: 64,
      y: 238,
      width: 640,
      height: 1136,
    },
    viewport: {
      width: 320,
      height: 568,
    },
    variants: [
      {
        id: 'gray',
        title: 'Space gray',
        mockStageFile: 'iPhone5s/Apple iPhone 5s Space Gray.png',
        button_color: '#4E4E50',
      },
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iPhone5s/Apple iPhone 5s Silver.png',
        button_color: '#C7C8C7',
      },
      {
        id: 'gold',
        title: 'Gold',
        mockStageFile: 'iPhone5s/Apple iPhone 5s Gold.png',
        button_color: '#E5CDBC',
      },
    ],
  },
  {
    id: 'iphone-original',
    name: 'Original iPhone',
    iconName: 'IPhone58',
    category: 'phones',
    screen_positions: {
      x: 65,
      y: 261,
      width: 669,
      height: 999,
    },
    viewport: {
      width: 666,
      height: 999,
    },
    variants: [
      {
        id: 'black',
        title: 'Black',
        mockStageFile: 'iPhoneOriginal/AppleIphoneOriginal.png',
        button_color: '#000000',
      },
    ],
  },
  {
    id: 'rabbit-r1',
    name: 'Rabbit R1 AI device',
    iconName: 'IPhone58',
    category: 'ai-devices',
    hasDifferentOrientations: false,
    screen_positions: {
      x: 50,
      y: 52,
      width: 1097,
      height: 1458,
    },
    viewport: {
      width: 1097,
      height: 1458,
    },
    variants: [
      {
        id: 'orange',
        title: 'Orange',
        mockStageFile: 'RabbitR1/rabbit-r1.png',
        maskFile: 'RabbitR1/mask.png',
        button_color: '#FF3F00',
      },
    ],
  },
  {
    id: 'apple-ipad-9th-gen',
    name: 'Apple iPad · 9th gen.',
    iconName: 'IPad',
    category: 'tablets',
    screen_positions: {
      x: 97,
      y: 225,
      width: 1620,
      height: 2160,
    },
    viewport: {
      width: 810,
      height: 1080,
    },
    variants: [
      {
        id: 'silver',
        title: '10.2" Silver',
        mockStageFile: 'iPad/10.2Inch/9thGen/apple-ipad-9th-gen-silver.png',
        maskFile: 'iPad/10.2Inch/9thGen/mask.png',
        button_color: '#DFE0E0',
      },
    ],
  },
  {
    id: 'apple-ipad-air-5th-gen',
    name: 'Apple iPad Air · 5th gen.',
    iconName: 'IPadPro',
    category: 'tablets',
    screen_positions: {
      x: 110,
      y: 114,
      width: 1640,
      height: 2360,
    },
    viewport: {
      width: 820,
      height: 1180,
    },
    variants: [
      {
        id: 'spacegray',
        title: '10.9" Space Gray',
        mockStageFile:
          'iPadAir/10.9Inch/5thGen/apple-ipad-air-10-9inch-5th-gen-space-gray.png',
        maskFile: 'iPadAir/10.9Inch/5thGen/mask.png',
        button_color: '#7A787C',
      },
    ],
  },
  {
    id: 'apple-ipad-pro-6th-gen',
    name: 'Apple iPad Pro · 6th gen.',
    iconName: 'IPadPro',
    category: 'tablets',
    screen_positions: {
      x: 96,
      y: 100,
      width: 2048,
      height: 2732,
    },
    viewport: {
      width: 1024,
      height: 1366,
    },
    variants: [
      {
        id: 'spacegray',
        title: '12.9" Space Gray',
        mockStageFile:
          'iPadPro/12.9Inch/6thGen/apple-ipad-12-9inch-pro-6th-gen-space-gray.png',
        maskFile: 'iPadPro/12.9Inch/6thGen/mask.png',
        button_color: '#ACAEB2',
      },
    ],
  },
  {
    id: 'apple-ipad-mini-6th-gen',
    name: 'Apple iPad Mini · 6th gen.',
    iconName: 'IPadPro',
    category: 'tablets',
    screen_positions: {
      x: 120,
      y: 124,
      width: 1488,
      height: 2266,
    },
    viewport: {
      width: 744,
      height: 1133,
    },
    variants: [
      {
        id: 'starlight',
        title: '8.3" Starlight',
        mockStageFile:
          'iPadMini/8.3Inch/6thGen/apple-ipad-8-3-inch-6th-gen-starlight.png',
        maskFile: 'iPadMini/8.3Inch/6thGen/mask.png',
        button_color: '#FAF7F3',
      },
    ],
  },
  {
    id: 'apple-watch-series-10',
    name: 'Apple Watch Series 10',
    iconName: 'AppleWatch6',
    hasDifferentOrientations: false,
    category: 'watches',
    screen_positions: {
      x: 40,
      y: 176,
      width: 416,
      height: 496,
    },
    viewport: {
      width: 208,
      height: 248,
    },
    variants: [
      {
        id: 'apple-watch-s10-46mm-aluminium-silver',
        title: '46mm Silver Aluminium',
        mockStageFile:
          'AppleWatchSeries10/apple-watch-s10-46mm-aluminium-silver.png',
        maskFile: 'AppleWatchSeries10/mask.png',
        button_color: '#9D9D9F',
      },
      {
        id: 'apple-watch-s10-46mm-aluminium-rose-gold',
        title: '46mm Rose Gold Aluminium',
        mockStageFile:
          'AppleWatchSeries10/apple-watch-s10-46mm-aluminium-rose-gold.png',
        maskFile: 'AppleWatchSeries10/mask.png',
        button_color: '#CAADA0',
      },
      {
        id: 'apple-watch-s10-46mm-aluminium-jet-black',
        title: '46mm Jet Black Aluminium',
        mockStageFile:
          'AppleWatchSeries10/apple-watch-s10-46mm-aluminium-jet-black.png',
        maskFile: 'AppleWatchSeries10/mask.png',
        button_color: '#1F1E20',
      },
      {
        id: 'apple-watch-s10-46mm-titanium-natural',
        title: '46mm Natural Titanium',
        mockStageFile:
          'AppleWatchSeries10/apple-watch-s10-46mm-titanium-natural.png',
        maskFile: 'AppleWatchSeries10/mask.png',
        button_color: '#BEB9AC',
      },
      {
        id: 'apple-watch-s10-46mm-titanium-gold',
        title: '46mm Gold Titanium',
        mockStageFile:
          'AppleWatchSeries10/apple-watch-s10-46mm-titanium-gold.png',
        maskFile: 'AppleWatchSeries10/mask.png',
        button_color: '#A18A74',
      },
      {
        id: 'apple-watch-s10-46mm-titanium-slate',
        title: '46mm Slate Titanium',
        mockStageFile:
          'AppleWatchSeries10/apple-watch-s10-46mm-titanium-slate.png',
        maskFile: 'AppleWatchSeries10/mask.png',
        button_color: '#0F0E08',
      },
    ],
  },
  {
    id: 'apple-watch-series-8',
    name: 'Apple Watch Series 8',
    iconName: 'AppleWatch6',
    hasDifferentOrientations: false,
    category: 'watches',
    archived: true,
    screen_positions: {
      x: 39,
      y: 177,
      width: 396,
      height: 484,
    },
    viewport: {
      width: 198,
      height: 242,
    },
    variants: [
      {
        id: 'apple-watch-s8-45mm-alu-midnight',
        title: '45mm Midnight',
        mockStageFile:
          'AppleWatchSeries8/apple-watch-s8-45mm-aluminium-midnight.png',
        maskFile: 'AppleWatchSeries8/mask.png',
        button_color: '#3E454E',
      },
      {
        id: 'apple-watch-s8-45mm-alu-silver',
        title: '45mm Silver',
        mockStageFile:
          'AppleWatchSeries8/apple-watch-s8-45mm-aluminium-silver.png',
        maskFile: 'AppleWatchSeries8/mask.png',
        button_color: '#CDCDCF',
      },
      {
        id: 'apple-watch-s8-45mm-alu-starlight',
        title: '45mm Starlight',
        mockStageFile:
          'AppleWatchSeries8/apple-watch-s8-45mm-aluminium-starlight.png',
        maskFile: 'AppleWatchSeries8/mask.png',
        button_color: '#E1D7D0',
      },
    ],
  },
  {
    id: 'apple-watch-ultra-2',
    name: 'Apple Watch Ultra 2',
    iconName: 'AppleWatchUltra',
    hasDifferentOrientations: false,
    category: 'watches',
    screen_positions: {
      x: 61,
      y: 211,
      width: 410,
      height: 502,
    },
    viewport: {
      width: 205,
      height: 251,
    },
    variants: [
      {
        id: 'natural-titanium',
        title: 'Natural Titanium',
        mockStageFile:
          'AppleWatchUltra2/apple-watch-ultra-2-natural-titanium.png',
        maskFile: 'AppleWatchUltra2/mask.png',
        button_color: '#DED6CC',
      },
      {
        id: 'black-titanium',
        title: 'Black Titanium',
        mockStageFile:
          'AppleWatchUltra2/apple-watch-ultra-2-black-titanium.png',
        maskFile: 'AppleWatchUltra2/mask.png',
        button_color: '#333231',
      },
    ],
  },
  {
    id: 'apple-watch-ultra',
    name: 'Apple Watch Ultra',
    iconName: 'AppleWatchUltra',
    hasDifferentOrientations: false,
    category: 'watches',
    archived: true,
    screen_positions: {
      x: 61,
      y: 212,
      width: 410,
      height: 502,
    },
    viewport: {
      width: 205,
      height: 251,
    },
    variants: [
      {
        id: 'apple-watch-ultra',
        title: 'Ultra',
        mockStageFile: 'AppleWatchUltra/apple-watch-ultra-alphine.png',
        maskFile: 'AppleWatchUltra/mask.png',
        button_color: '#E5DCD4',
      },
    ],
  },
  {
    id: 'apple-watch-series-6',
    name: 'Apple Watch Series 6',
    iconName: 'AppleWatch6',
    hasDifferentOrientations: false,
    category: 'watches',
    archived: true,
    screen_positions: {
      x: 125,
      y: 235,
      width: 368,
      height: 448,
    },
    viewport: {
      width: 197,
      height: 162,
    },
    variants: [
      {
        id: '40mm-green-dark-titanium',
        title: '40 mm green band',
        mockStageFile:
          'AppleWatchSeries6/Cyprus Green Solo Loop/40mm/Apple Watch 40mm Dark Titanium Cyprus Green Solo Loop.png',
        maskFile: 'AppleWatchSeries6/mask-white.png',
        button_color: '#373D32',
      },
      {
        id: '40mm-ginger-dark-titanium',
        title: '40 mm ginger band',
        mockStageFile:
          'AppleWatchSeries6/Ginger Solo Loop/40mm/Apple Watch 40mm Dark Titanium Ginger Solo Loop.png',
        maskFile: 'AppleWatchSeries6/mask-white.png',
        button_color: '#EDD887',
      },
      {
        id: '40mm-pink-dark-titanium',
        title: '40 mm pink band',
        mockStageFile:
          'AppleWatchSeries6/Pink Citrus Sport Closed/40mm/Apple Watch 40mm Dark Titanium Pink Citrus Sport.png',
        maskFile: 'AppleWatchSeries6/mask-white.png',
        button_color: '#D17469',
      },
    ],
  },
  {
    id: 'huawei-p8',
    name: 'Huawei P8',
    iconName: 'HuaweiP8',
    category: 'phones',
    screen_positions: {
      x: 100,
      y: 320,
      width: 1080,
      height: 1920,
    },
    viewport: {
      width: 360,
      height: 640,
    },
    variants: [
      {
        id: 'black',
        title: 'Black',
        mockStageFile: 'HuaweiP8/Huawei P8 Black.png',
        button_color: '#181B1F',
      },
      {
        id: 'white',
        title: 'White',
        mockStageFile: 'HuaweiP8/Huawei P8 White.png',
        button_color: '#F5F5F5',
      },
    ],
  },
  {
    id: 'samsung-frame',
    name: 'Samsung Frame TV',
    iconName: 'SamsungFrame',
    hasDifferentOrientations: false,
    category: 'tvs',
    viewport: {
      width: 3840,
      height: 2160,
    },
    variantsHasLabels: true,
    variants: [
      {
        id: 'brown',
        title: 'With stand',
        mockStageFile: 'SamsungFrameTv/Samsung Frame - Brown.png',
        screen_positions: {
          x: 202,
          y: 249,
          width: 3840,
          height: 2165,
        },
      },
      {
        id: 'brown-with-stand',
        title: 'With Studio stand',
        mockStageFile:
          'SamsungFrameTv/Samsung Frame - Brown with Studio Stand.png',
        screen_positions: {
          x: 202,
          y: 209,
          width: 3840,
          height: 2165,
        },
      },
      {
        id: 'brown-without-stand',
        title: 'Without stand',
        mockStageFile: 'SamsungFrameTv/Samsung Frame - Brown without Stand.png',
        screen_positions: {
          x: 202,
          y: 249,
          width: 3840,
          height: 2165,
        },
      },
    ],
  },
  {
    id: 'motorola-moto-360',
    name: 'Motorola Moto 360',
    iconName: 'Moto360',
    hasDifferentOrientations: false,
    category: 'watches',
    screen_positions: {
      x: 43,
      y: 138,
      width: 559,
      height: 559,
    },
    viewport: {
      width: 320,
      height: 290,
    },
    variants: [
      {
        id: 'closed-black',
        title: 'Black',
        mockStageFile:
          'MotorolaMoto360/DeviceClosed/MotorolaMoto360MenBlackBlackClosed.png',
        maskFile: 'MotorolaMoto360/DeviceClosed/mask-white.png',
        button_color: '#171513',
      },
      {
        id: 'closed-gold',
        title: 'Gold',
        mockStageFile:
          'MotorolaMoto360/DeviceClosed/MotorolaMoto360MenGoldBlackClosed.png',
        maskFile: 'MotorolaMoto360/DeviceClosed/mask-white.png',
        button_color: '#C2AA7C',
      },
      {
        id: 'closed-silver',
        title: 'Silver',
        mockStageFile:
          'MotorolaMoto360/DeviceClosed/MotorolaMoto360MenSilverBlackClosed.png',
        maskFile: 'MotorolaMoto360/DeviceClosed/mask-white.png',
        button_color: '#E0E1DB',
      },
      {
        id: 'closed-rosegold',
        title: 'Rose Gold',
        mockStageFile:
          'MotorolaMoto360/DeviceClosed/MotorolaMoto360WomenRoseGoldStoneGreyClosed.png',
        maskFile: 'MotorolaMoto360/DeviceClosed/mask-white.png',
        button_color: '#CBB19A',
        screen_positions: {
          x: 43,
          y: 117,
          width: 559,
          height: 559,
        },
      },
    ],
  },
  {
    id: 'samsung-galaxy-s20',
    name: 'Samsung Galaxy S20',
    iconName: 'SamsungGalaxyS20',
    category: 'phones',
    screen_positions: {
      x: 44,
      y: 57,
      width: 1440,
      height: 3200,
    },
    viewport: {
      width: 360,
      height: 800,
    },
    variants: [
      {
        id: 'blue',
        title: 'Blue',
        mockStageFile: 'SamsungGalaxyS20/Samsung Galaxy S20 Cloud Blue.png',
        maskFile: 'SamsungGalaxyS20/mask-white.png',
        button_color: '#8D9AA6',
      },
      {
        id: 'pink',
        title: 'Pink',
        mockStageFile: 'SamsungGalaxyS20/Samsung Galaxy S20 Cloud Pink.png',
        maskFile: 'SamsungGalaxyS20/mask-white.png',
        button_color: '#C0A4AA',
      },
      {
        id: 'gray',
        title: 'Gray',
        mockStageFile: 'SamsungGalaxyS20/Samsung Galaxy S20 Cosmic Gray.png',
        maskFile: 'SamsungGalaxyS20/mask-white.png',
        button_color: '#69696E',
      },
    ],
  },
  {
    id: 'google-pixel-4',
    name: 'Google Pixel 4',
    iconName: 'Pixel4',
    category: 'phones',
    screen_positions: {
      x: 97,
      y: 198,
      width: 1084,
      height: 2284,
    },
    viewport: {
      width: 393,
      height: 830,
    },
    variantsHasLabels: true,
    variants: [
      {
        id: 'black',
        title: 'Just Black',
        mockStageFile: 'GooglePixel4/Google Pixel 4 Just Black.png',
        maskFile: 'GooglePixel4/mask-white.png',
      },
      {
        id: 'white',
        title: 'Clearly White',
        mockStageFile: 'GooglePixel4/Google Pixel 4 Clearly White.png',
        maskFile: 'GooglePixel4/mask-white.png',
      },
      {
        id: 'orange',
        title: 'Oh so Orange',
        mockStageFile: 'GooglePixel4/Google Pixel 4 Oh So Orange.png',
        maskFile: 'GooglePixel4/mask-white.png',
      },
    ],
  },
  {
    id: 'macbook-air-13-inch-4th-gen',
    name: 'Macbook Air · 4th gen.',
    iconName: 'MacbookPro',
    hasDifferentOrientations: false,
    category: 'laptops',
    screen_positions: {
      x: 325,
      y: 66,
      width: 2560,
      height: 1664,
    },
    viewport: {
      width: 2560,
      height: 1664,
    },
    variants: [
      {
        id: 'midnight',
        title: 'Midnight · 13"',
        mockStageFile:
          'MacBookAir/13Inch/4thGen/macbook-air-13-inch-4th-gen-midnight.png',
        maskFile: 'MacBookAir/13Inch/4thGen/mask.png',
        button_color: '#2E3641',
      },
    ],
  },
  {
    id: 'macbook-pro-14-inch-5th-gen',
    name: 'MacBook Pro · 5th gen.',
    iconName: 'MacbookPro',
    hasDifferentOrientations: false,
    category: 'laptops',
    screen_positions: {
      x: 390,
      y: 55,
      width: 3024,
      height: 1964,
    },
    viewport: {
      width: 1512,
      height: 982,
    },
    variants: [
      {
        id: 'silver-14-inch',
        title: 'Silver · 14"',
        mockStageFile:
          'MacBookPro/14Inch/5thGen/macbook-pro-14-inch-5th-gen-silver.png',
        maskFile: 'MacBookPro/14Inch/5thGen/mask.png',
        button_color: '#DFE1E0',
      },
    ],
  },
  {
    id: 'macbook-pro-15-inch-4th-gen',
    name: 'MacBook Pro · 4th gen.',
    iconName: 'MacbookPro',
    hasDifferentOrientations: false,
    category: 'laptops',
    screen_positions: {
      x: 398,
      y: 130,
      width: 2580,
      height: 1612,
    },
    viewport: {
      width: 1440,
      height: 900,
    },
    variants: [
      {
        id: 'silver-15-inch',
        title: 'Silver · 15"',
        mockStageFile:
          'MacBookPro/15Inch/4thGen/macbook-pro-15-inch-4th-gen-silver.png',
        maskFile: 'MacBookPro/15Inch/4thGen/mask.png',
        button_color: '#DCDFDE',
      },
    ],
  },
  {
    id: 'imac-24-inch-2021',
    name: 'iMac 24"',
    iconName: 'Imac24Inch',
    hasDifferentOrientations: false,
    category: 'desktops',
    screen_positions: {
      x: 118,
      y: 118,
      width: 4480,
      height: 2520,
    },
    viewport: {
      width: 2560,
      height: 1440,
    },
    variants: [
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iMac/24Inch/2021/imac-24-inch-2021-silver.png',
        maskFile: 'iMac/24Inch/2021/mask.png',
        button_color: '#C7C8CA',
      },
    ],
  },
  {
    id: 'imac-27-inch-2020',
    name: 'iMac 27"',
    iconName: 'IMac',
    hasDifferentOrientations: false,
    category: 'desktops',
    screen_positions: {
      x: 230,
      y: 230,
      width: 5120,
      height: 2880,
    },
    viewport: {
      width: 2560,
      height: 1240,
    },
    variants: [
      {
        id: 'silver',
        title: 'Silver',
        mockStageFile: 'iMac/27Inch/2020/imac-27-inch-2020-silver.png',
        maskFile: 'iMac/27Inch/2020/mask.png',
        button_color: '#C7C8CA',
      },
    ],
  },
  {
    id: 'apple-pro-display-xdr',
    name: 'Apple Pro Display XDR',
    iconName: 'AppleProDisplay',
    hasDifferentOrientations: false,
    category: 'displays',
    screen_positions: {
      x: 151,
      y: 151,
      width: 6020,
      height: 3395,
    },
    viewport: {
      width: 2560,
      height: 1440,
    },
    variants: [
      {
        id: 'pro-display-xdr',
        title: 'Pro Display XDR',
        mockStageFile: 'AppleProDisplayXDR/AppleProDisplayXDR.png',
        button_color: '#9A9EA1',
      },
    ],
  },
]
