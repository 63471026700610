import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  active?: boolean
  name: string
  color: string
  light?: boolean
  onClick?: () => void
}

const DeviceVariantText: React.FC<Props> = (props) => {
  const { active, name, color, light, onClick } = props
  return (
    <Label
      active={active}
      color={color}
      light={light ? light : false}
      onClick={onClick}
      id={`variant-${name}`}
      className="device-variant-button"
    >
      <span>{name}</span>
    </Label>
  )
}

const Label = styled(motion.button)<{
  active?: boolean
  color: string
  light?: boolean
  id?: string
}>`
  outline: 0;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  background-color: ${({ theme }) => theme.color.background2};
  padding: 5px 4px 5px 8px;
  text-align: left;
  margin-bottom: 5px;
  border-radius: 8px;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.borderDark};
  }
  ${(props) =>
    props.active &&
    `
    background-color: ${props.theme.color.borderDark};
  `}
`

export default DeviceVariantText
