import React from 'react'
import { useStageState } from '../state/stage.state'
import { addNotification } from '../state/notifications.state'
import { useFileReaderHelper } from '../hooks/useFileReader'
import { motion } from 'framer-motion'
import UIButton from './UIButton'

const DesignUploadButton: React.FC = () => {
  const stageState = useStageState()
  let activeNode = stageState.activeNode

  const fileReader = useFileReaderHelper()

  const handleFileChangeUpload = (event: any) => {
    handleUpload(event.target.files)

    event.target.value = ''
  }

  const handleUpload = (files: FileList) => {
    const allowedMimeTypes = ['image/jpeg', 'image/png']

    // Do something with the files
    if (activeNode !== undefined && files.length) {
      //Check for allowed mime-types
      if (!allowedMimeTypes.includes(files[0].type)) {
        addNotification({
          message:
            "Can't process file, we currently only support jpg and png files",
        })
        return
      }

      fileReader.readAsDataURL(files[0])
    }
  }

  return (
    <>
      <UIButton as={motion.label}>
        {activeNode &&
        activeNode.attributes.designFileContents &&
        activeNode.attributes.designFileContents.length
          ? 'Replace...'
          : 'Select file...'}
        <input
          className="visually-hidden"
          type="file"
          onChange={handleFileChangeUpload}
        />
      </UIButton>
    </>
  )
}

export default DesignUploadButton
