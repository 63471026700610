import React from 'react'
import styled from 'styled-components'
import DeviceVariantText from './DeviceVariantText'
import DeviceVariant from '../entities/DeviceVariant'
import { useStageState } from '../state/stage.state'
import DeviceVariantColor from './DeviceVariantColor'
import { setActiveDevice } from '../helpers/DeviceStateSetter'

interface Props {
  variants: Array<DeviceVariant>,
  useLabels: Boolean
}

const DeviceVariants: React.FC<Props> = (props) => {
  const { variants, useLabels } = props

  const stageState = useStageState()

  const activeNode = stageState.activeNode
  const activeVariant = activeNode?.attributes.variant

  const setActiveVariant = (variant: DeviceVariant) => {
    // Return if activeNode doesn't have variants
    if (activeVariant === undefined) {
      return
    }

    if (!activeNode || !activeNode.attributes.device) {
      return
    }

    setActiveDevice(activeNode, activeNode.attributes.device, variant)
  }

  return (
    useLabels ?
      <Labels id='device-variants'>
        {variants.map((variant) => (
          <DeviceVariantText
            key={variant.data.id}
            name={variant.data.title}
            color={variant.data.button_color || 'black'}
            onClick={() => setActiveVariant(variant)}
            active={activeVariant?.data.id === variant.data.id}
          />
        ))}
      </Labels>
      :
      <Colors id='device-variants'>
        {variants.map((variant) => (
          <DeviceVariantColor
            key={variant.data.id}
            name={variant.data.title}
            color={variant.data.button_color || 'black'}
            onClick={() => setActiveVariant(variant)}
            active={activeVariant?.data.id === variant.data.id}
          />
        ))}
      </Colors>
  )
}

const Colors = styled.div<{ id?: string }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 8px;
  row-gap: 4px;
`

const Labels = styled.div<{ id?: string }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`

export default DeviceVariants
