import { DeviceOrientation, setStageAndSetRerender, useStageState } from '../state/stage.state'
import cropDesignByDeviceVariant from '../renderers/DesignCropper'

export const useFileReaderHelper = () => {
  const fileReader = new FileReader()
  const stageState = useStageState()
  let activeNode = stageState.activeNode

  fileReader.onloadend = () => {
    if (typeof fileReader.result !== 'string') {
      return
    }

    if (activeNode === undefined) {
      return
    }

    const designImage = new Image()
    designImage.onload = () => {
      if (activeNode === undefined) {
        return
      }

      if (
        designImage.width > designImage.height &&
        activeNode.attributes.variant?.hasDifferentOrientations()
      ) {
        activeNode.attributes.deviceOrientation = DeviceOrientation.Landscape
      } else {
        activeNode.attributes.deviceOrientation = DeviceOrientation.Portrait
      }

      activeNode.attributes.designFileContents = designImage.src
      activeNode.attributes.originalDesignFileContents = designImage.src
      activeNode.resetCropValues()

      //Make default crop
      if (activeNode.attributes.variant) {
        const cropResult = cropDesignByDeviceVariant(designImage.src, activeNode.attributes.variant, activeNode.attributes.deviceOrientation)
        //todo remove if..
        if (cropResult?.cropValues) {
          activeNode.attributes.designFileContents = cropResult?.image
          activeNode.attributes.designCropValues = cropResult?.cropValues
        }
      }


      setStageAndSetRerender({ activeNode: activeNode })
    }
    designImage.src = fileReader.result
  }

  return fileReader
}
