import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<clipPath id="portrait_svg__a"><path d="M0 0h16v16H0z" /></clipPath><g clipPath="url(#portrait_svg__a)" clipRule="evenodd" fill="currentColor" fillRule="evenodd"><path d="M5.5 13.5A.5.5 0 016 13h4a.5.5 0 010 1H6a.5.5 0 01-.5-.5z" /><path d="M10.5 1h-5A1.5 1.5 0 004 2.5v11A1.5 1.5 0 005.5 15h5a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0010.5 1zm-5-1A2.5 2.5 0 003 2.5v11A2.5 2.5 0 005.5 16h5a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0010.5 0z" /></g></svg>;
});
const SvgPortrait = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgPortrait;