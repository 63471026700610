import { motion } from 'framer-motion'

import styled from 'styled-components'

const UIButton = styled(motion.button).attrs((props) => ({
  whileTap: { scale: 0.95 },
}))`
  ${({ theme }) => theme.type.UIRegular};
  background-color: ${({ theme }) => theme.color.background2};
  color: ${({ theme }) => theme.color.text1};
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 8px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
  outline: 0;
  &:hover,
  &:focus-within {
    background-color: ${({ theme }) => theme.color.background3};
  }
`

export default UIButton
