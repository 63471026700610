import styled from 'styled-components'
import React, {ReactNode, useState} from "react";
import Icon from "./icons";
import UIIconButton from "./UIIconButton";

interface AnnouncementBarProps {
    children: ReactNode,
    announcementId?: string //to keep new announcements visible after dismissing
}

const AnnouncementBar = ({children, announcementId = 'default-announcement'}: AnnouncementBarProps) => {
    const [isOpen, setIsOpen] = useState(null === localStorage.getItem(announcementId))

    const handleDismissBar = () => {
        localStorage.setItem(announcementId, 'dismissed')
        setIsOpen(false)
    }

    if(!isOpen){
        return null
    }

    return <Bar>
        <StyledUIIconButton
            onClick={handleDismissBar}
            aria-label="Dismiss announcement"
        >
            <Icon name="Close"/>
        </StyledUIIconButton>
        {children}
    </Bar>
}

const Bar = styled.div`
  color: #fff;
  background-color: #2c042a;
  text-align: center;
  padding: 16px;
  width: 100%;
  position: relative;

  a {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 1px;
  }
`

const StyledUIIconButton = styled(UIIconButton)`
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  fill: red;
  font-size: 16px;

  &:hover, &:focus {
    background-color: ${({theme}) => theme.color.actionDark};
  }
`

export default AnnouncementBar
