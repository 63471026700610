import React from 'react'
import {
    DeviceOrientation,
    setStageAndSetRerender,
    useStageState,
} from '../../../state/stage.state'
import DeviceInputField from '../../DeviceInputField'
import DevicePopOver from '../../DevicePopOver'
import PanelSection from '../../PanelSection'
import Property from '../../Property'
import SegmentedControl from '../../SegmentedControl'
import styled from 'styled-components'
import UploadDesignSection from '../UploadDesignSection'
import DeviceVariants from '../../DeviceVariants'

const DeviceNodeProperties = () => {
    const stageState = useStageState()

    const activeNode = stageState.activeNode
    const activeDevice = activeNode?.attributes.device
    const activeVariant = activeNode?.attributes.variant

    const setDeviceOrientation = (orientation: DeviceOrientation) => {
        if (activeNode) {
            if (activeVariant === undefined) {
                return
            }

            // Set orientation
            activeNode.attributes.deviceOrientation = orientation
            activeNode.resetCropValues()
            setStageAndSetRerender({activeNode: activeNode})
        }
    }

    return (
        <>
            {activeNode && (
                <Sections>
                    <PanelSection title="Mockup">
                        <Property label="Device">
                            <DevicePopOver activeDevice={activeDevice}>
                                <PopOverContents>
                                    <DeviceInputField
                                        label={
                                            activeDevice ? activeDevice.data.name : 'Select a device'
                                        }
                                    />
                                </PopOverContents>
                            </DevicePopOver>
                        </Property>

                        {activeDevice && activeVariant && (
                            <>
                                <Property label="Variant" alignLabel="start" labelFromTop={4}>
                                    <DeviceVariants
                                        variants={activeDevice.getVariants()}
                                        useLabels={activeDevice.data.variantsHasLabels ?? false}
                                    />
                                </Property>

                                {
                                    activeVariant.getAlert() && (
                                        <DeviceAlert dangerouslySetInnerHTML={{__html: activeVariant.getAlert()}}></DeviceAlert>
                                    )
                                }

                                {activeVariant.hasDifferentOrientations() && (
                                    <Property
                                        label="Orientation"
                                        alignLabel="start"
                                        labelFromTop={6}
                                    >
                                        <SegmentedControl
                                            showLabels
                                            typeLabel="DeviceOrientation"
                                            segments={[
                                                {
                                                    id: DeviceOrientation.Portrait,
                                                    icon: activeDevice.data.iconName,
                                                    label: 'Portrait',
                                                },
                                                {
                                                    id: DeviceOrientation.Landscape,
                                                    icon: activeDevice.data.iconName,
                                                    label: 'Landscape',
                                                    rotate: 90,
                                                },
                                            ]}
                                            handleChange={(orientation: DeviceOrientation) =>
                                                setDeviceOrientation(orientation)
                                            }
                                            activeValue={activeNode.attributes.deviceOrientation}
                                        />
                                    </Property>
                                )}
                            </>
                        )}
                    </PanelSection>
                    <UploadDesignSection/>
                </Sections>
            )}
        </>
    )
}

const PopOverContents = styled.div`
  width: inherit;
  flex: inherit;
`

const Sections = styled.div`
  width: 100%;
`

const DeviceAlert = styled.p<{ dangerouslySetInnerHTML?: any }>`
  background: ${({theme}) => theme.color.background2};
  padding: 10px;
  margin-bottom: 20px;
  color: ${({theme}) => theme.color.text1};
  font-style: italic;
  border-radius: 10px;
`

export default DeviceNodeProperties
