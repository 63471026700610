import {IDeviceVariantData} from '../assets/devices'
import Device from './Device'
import {DeviceOrientation} from '../state/stage.state'

export default class DeviceVariant {
    public data: IDeviceVariantData
    public device: Device

    constructor(deviceVariantData: IDeviceVariantData, device: Device) {
        this.data = deviceVariantData
        this.device = device
    }

    getMockStageFilePath(): string {
        return `${process.env.PUBLIC_URL}/assets/mockstages/${this.data.mockStageFile}`
    }

    getMaskFile(): string | undefined {
        if (this.data.maskFile === undefined) {
            return undefined
        }

        return `${process.env.PUBLIC_URL}/assets/mockstages/${this.data.maskFile}`
    }

    hasDifferentOrientations(): boolean {
        if (this.device.data.hasDifferentOrientations === false) {
            return false
        }

        return this.data.hasDifferentOrientations !== false
    }

    getAlert(): string | undefined {
        return this.device.getAlert() ?? this.data.alert ?? undefined
    }

    getDesignAspect(orientation: DeviceOrientation) {
        const positionValues = this.getDesignPositions()

        if (orientation === DeviceOrientation.Landscape) {
            return (positionValues?.height ?? 1) / (positionValues?.width ?? 1)
        }

        return (positionValues?.width ?? 1) / (positionValues?.height ?? 1)
    }

    getDesignPositions() {
        return {
            x:
                this.data?.screen_positions?.x ?? this.device.data?.screen_positions?.x,
            y:
                this.data?.screen_positions?.y ?? this.device.data?.screen_positions?.y,
            width:
                this.data?.screen_positions?.width ??
                this.device.data?.screen_positions?.width,
            height:
                this.data?.screen_positions?.height ??
                this.device.data?.screen_positions?.height,
        }
    }

    getViewportDimensions() {
        if (!this.data.viewport && !this.device.data.viewport) {
            return {
                width: this.getDesignPositions().width,
                height: this.getDesignPositions().height,
            }
        }

        return {
            width: this.data?.viewport?.width ?? this.device.data?.viewport?.width,
            height: this.data?.viewport?.height ?? this.device.data?.viewport?.height,
        }
    }

    public static getById(device: Device, id: string) {
        const variantData = device.data.variants.find((variant) => {
            return variant.id === id
        })

        return variantData && new DeviceVariant(variantData, device)
    }
}
