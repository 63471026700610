import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path clipRule="evenodd" d="M5.377.859l-.312 2.189A2 2 0 003.5 5v6a2 2 0 001.565 1.952l.312 2.19a1 1 0 00.99.858h3.266a1 1 0 00.99-.859l.312-2.188A2 2 0 0012.5 11V7.5A.5.5 0 0013 7V6a.5.5 0 00-.5-.5V5a2 2 0 00-1.565-1.952l-.312-2.19A1 1 0 009.633 0H6.367a1 1 0 00-.99.859zM4.5 5a1 1 0 011-1h5a1 1 0 011 1v6a1 1 0 01-1 1h-5a1 1 0 01-1-1z" fill="currentColor" fillRule="evenodd" /></svg>;
});
const SvgAppleWatch6 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgAppleWatch6;