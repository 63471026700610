import React, { useCallback } from 'react'
import styled from 'styled-components'
import PopOver from './PopOver'
import Stack from './Stack'
import {
  DeviceOrientation,
  setStageAndSetRerender,
  useStageState,
} from '../state/stage.state'

interface Props {
  children: React.ReactElement
  width?: number
  placement?:
    | 'bottom-center'
    | 'bottom-start'
    | 'bottom-end'
    | 'top-start'
    | 'top-center'
    | 'top-end'
    | 'left-end'
    | 'left-center'
    | 'left-start'
    | 'right-end'
    | 'right-center'
    | 'right-start'
    | 'center'
  autoPlacement?: boolean
}

const MainMenu: React.FC<Props> = (props) => {
  const { children, width, placement, autoPlacement } = props
  const stageState = useStageState()

  const clearCanvas = useCallback(() => {
    const updatedActiveNode = stageState.activeNode
    if (updatedActiveNode) {
      updatedActiveNode.attributes.designFileContents = undefined
      updatedActiveNode.attributes.deviceOrientation =
        DeviceOrientation.Portrait
    }
    setStageAndSetRerender({
      zoomLevel: stageState.fitZoomLevel,
      activeNode: updatedActiveNode,
    })
  }, [stageState.fitZoomLevel, stageState.activeNode])

  const items = (
    <Stack direction="vertical" gap={0}>
      <Section gap={0} direction="vertical">
        <MenuItem onClick={clearCanvas} data-closepopover>
          Empty Canvas
        </MenuItem>
      </Section>
      <Section gap={0} direction="vertical">
        <MenuItem as="a" href="https://mockmagic.com" target="_blank">
          Homepage
        </MenuItem>
        <MenuItem as="a" href="https://mockmagic.com/whats-new" target="_blank">
          What's new
        </MenuItem>
        <MenuItem
          data-az-l="3f08f383-2ad5-427f-a19a-b3fcc0249342"
          className="feedback-button"
          id="main-menu-feedback-button"
        >
          Give feedback
        </MenuItem>
      </Section>
      <Section gap={0} direction="vertical">
        <MenuItem
          small
          as="a"
          href="https://twitter.com/mockmagic"
          target="_blank"
        >
          Twitter – @mockmagic
        </MenuItem>
        <MenuItem
          small
          as="a"
          href="https://mockmagic.com/terms-of-use"
          target="_blank"
        >
          Terms and privacy
        </MenuItem>
      </Section>
    </Stack>
  )

  return (
    <PopOver
      contents={<Content>{items}</Content>}
      autoPlacement={autoPlacement}
      placement={placement || 'bottom-start'}
      width={width}
      hideArrow
      initialFocus="#popOverContainer"
    >
      {children}
    </PopOver>
  )
}

const Content = styled.div`
  padding: 4px 0;
  background-color: ${({ theme }) => theme.color.glass};
  backdrop-filter: blur(10px);
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 8px;
`

const Section = styled(Stack)`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  margin-bottom: 4px;
  &:last-child {
    border-bottom-width: 0;
  }
`

const MenuItem = styled.button<{ small?: boolean }>`
  ${(props) =>
    props.small ? props.theme.type.UIRegular : props.theme.type.UILarge}
  min-width: 180px;
  padding: ${(props) => (props.small ? '8px' : '12px')} 12px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  outline: 0;
  color: ${(props) =>
    props.small ? props.theme.color.text2 : props.theme.color.text1};
  &:hover,
  &:focus {
    background: rgb(0 0 0 / 10%);
  }
`

export default MainMenu
