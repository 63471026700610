import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Canvas from './Canvas'
import { useHistory, useParams } from 'react-router-dom'
import Device from '../entities/Device'
import DeviceVariant from '../entities/DeviceVariant'
import { setActiveDevice } from '../helpers/DeviceStateSetter'
import { useStageState } from '../state/stage.state'

const Editor: React.FC = () => {
  const stageState = useStageState()

  /**
   * TODO: move the following lines (routes) to a better place, so this component can be abstract
   */
  const routeParams: any = useParams()
  const urlDevice = Device.getById(routeParams.device ?? undefined)
  const urlDeviceVariant = urlDevice && DeviceVariant.getById(urlDevice, routeParams.variant ?? undefined)

  const [urlChecked, setUrlChecked] = useState(false)

  const history = useHistory()

  useEffect(() => {
      if (stageState.activeNode && !urlChecked && urlDevice) {
        setUrlChecked(true)
        setActiveDevice(stageState.activeNode, urlDevice, urlDeviceVariant)
        //Modify URL
        history.push('/')

        return
      }

    }, [
      stageState.activeNode,
      urlDevice,
      urlDeviceVariant,
      history,
      urlChecked,
    ],
  )

  return (
    <StyledEditor>
      <Canvas />
    </StyledEditor>
  )
}

const StyledEditor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.background2};
  width: 100%;
  height: 100%;
`

export default Editor
