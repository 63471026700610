import React, { useEffect } from 'react'
import ReactAppzi from 'react-appzi'
import styled from 'styled-components'
import PanelSection from '../PanelSection'
import Type from '../Type'

const FeedbackSection = () => {
  useEffect(() => {
    // Initialize Appzi feedback module
    ReactAppzi.initialize('q23LN')
  }, [])

  return (
    <StyledPanelSection title="" borderBottom={false} borderTop>
      <Type
        variant="UISmall"
        textColor={(props: any) => props.theme.color.text3}
      >
        What can we do to make Mock Magic better for you?{' '}
        <TextButton data-az-l="3f08f383-2ad5-427f-a19a-b3fcc0249342" className="feedback-button" id="properties-panel-feedback-button">
          Give feedback
        </TextButton>
        .
      </Type>
    </StyledPanelSection>
  )
}

const StyledPanelSection = styled(PanelSection)`
  color: red;
`

const TextButton = styled.button`
  outline: 0;
  text-decoration: underline;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.actionDark};
  }
`

export default FeedbackSection
