import {devices, IDeviceData} from '../assets/devices'
import DeviceVariant from './DeviceVariant'

export default class Device {
    public data: IDeviceData

    constructor(deviceData: IDeviceData) {
        this.data = deviceData
    }

    getVariants(): Array<DeviceVariant> {
        return this.data.variants.map(variant => new DeviceVariant(variant, this))
    }

    getAlert(): string | undefined {
        return this.data.alert ?? undefined
    }

    public static getById(id: string) {
        const deviceData = (devices.find((device) => {
            return device.id === id
        }))

        return deviceData && new Device(deviceData)
    }

}
