import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g fill="currentColor"><path clipRule="evenodd" d="M4.5 0a1 1 0 00-1 1v14a1 1 0 001 1h7a1 1 0 001-1V1a1 1 0 00-1-1zM5 1a.5.5 0 00-.5.5V14a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V1.5A.5.5 0 0011 1z" fillRule="evenodd" /><circle cx={8} cy={2} r={0.5} /></g></svg>;
});
const SvgSamsungGalaxyS20 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgSamsungGalaxyS20;