import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react'
import * as PIXI from 'pixi.js'
import {Container} from 'pixi.js'
import {setStage, useStageState} from '../state/stage.state'
import styled from 'styled-components'
import {setDocument, useDocumentState} from '../state/document.state'
import PreRenderer from '../renderers/PreRenderer'

const Canvas: React.FC = () => {
    const documentState = useDocumentState()
    const stageState = useStageState()

    const wrapperRef = useRef<HTMLDivElement>(null)
    const rendererRef = useRef<PIXI.Application>()

    const zoomLevel = useStageState().zoomLevel

    function handleInitRenderer() {
        if (wrapperRef.current === null) {
            return
        }

        PIXI.utils.skipHello()

        rendererRef.current = new PIXI.Application({
            backgroundAlpha: 0,
            antialias: true,
            resolution: 1,
        })

        const preRenderer = new PreRenderer(rendererRef.current)
        return {
            viewport: preRenderer.addViewport(wrapperRef.current),
            renderer: preRenderer.render(wrapperRef.current),
        }
    }

    const initRenderer = useCallback(() => {
        setDocument({...handleInitRenderer()})
    }, [])

    const doRender = useCallback(() => {
        //(re)render
        if (documentState.viewport === undefined ||
            documentState.renderer === undefined ||
            stageState.activeNode === undefined) {
            return
        }

        stageState.activeNode.render(documentState.viewport, documentState.renderer).then(() => {
            if (
                !(stageState?.activeNode?.renderObject instanceof Container) ||
                documentState.viewport === undefined ||
                stageState?.activeNode?.renderObject?.height === undefined
            ) {
                return
            }

            //Set correct zoomlevel based on active node
            const fitWidthValue = stageState.activeNode.renderObject.width + 100
            const fitHeightValue = stageState.activeNode.renderObject.height + 100
            let zoomFactor = documentState.viewport.findFit(fitWidthValue, fitHeightValue)

            if (zoomFactor > 1) {
                zoomFactor = 1
            }

            documentState.viewport.setZoom(zoomFactor ?? 1, false)

            setStage({
                zoomLevel: Math.floor(zoomFactor * 100),
                fitZoomLevel: Math.floor(zoomFactor * 100),
            })

            documentState.viewport.moveCenter(
                stageState.activeNode.renderObject.x,
                stageState.activeNode.renderObject.y,
            )

        })
    }, [
        documentState.renderer,
        documentState.viewport,
        stageState.activeNode,
    ])

    useEffect(() => {
        initRenderer()
    }, [initRenderer])

    useLayoutEffect(() => {
        documentState.viewport &&
        Math.ceil(documentState.viewport.scale.x) !== zoomLevel &&
        documentState.viewport.setZoom((zoomLevel ?? 100) / 100)
    }, [zoomLevel, documentState.viewport])

    useLayoutEffect(() => {
        doRender()
    }, [
        doRender,
        stageState.rerenderCycle,
    ])

    useEffect(() => {
        if (
            wrapperRef.current === null ||
            documentState.viewport === undefined
        ) {
            return
        }

        if (stageState.nodes && stageState.nodes.length && documentState.viewport) {
            const promises: Array<Promise<void>> = []
            stageState.nodes?.forEach((node) => {
                if (documentState.viewport === undefined || documentState.renderer === undefined) {
                    return
                }

                promises.push(node.render(documentState.viewport, documentState.renderer))
            })

            Promise.all(promises).finally(() => {
                if (stageState.nodes !== undefined) {
                    const defaultNode = stageState.nodes[0]
                    setStage({
                        activeNode: stageState.nodes.filter(
                            (node) => node.id === defaultNode.id,
                        )[0],
                    })
                }
            })
        }

        return () => {
            if (documentState.renderer) {
                documentState.renderer.destroy(true)
            }
        }
    }, [
        documentState.viewport,
        documentState.renderer,
        stageState.nodes,
    ])

    return <StyledWrapper ref={wrapperRef}/>
}

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export default Canvas
