import React, { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import useDisableBodyScroll from '../hooks/useDisableBodyScroll'
import FocusTrap from 'focus-trap-react'
import { AnimatePresence, motion } from 'framer-motion'

interface Props {
  isVisible: boolean
  handleClose: () => void
  dismissable?: boolean
  dismissableViaOutsideClick?: boolean
  maxWidth?: number
}

// This Modal component provides the basic styling and functionality for modal-like components (alerts, modals, etc.) to work.
// Intentionally, most of the further styles are left to the consumer of this component to add.

const Modal: React.FC<Props> = (props) => {
  const {
    children,
    isVisible,
    handleClose,
    dismissable = true,
    dismissableViaOutsideClick = true,
    maxWidth = 640,
  } = props
  useDisableBodyScroll(isVisible)

  const renderLocation = document.getElementById('modal-root') || document.body

  const modalRef = useRef<HTMLDivElement>(null)

  // Close modal when click happens outside modal
  const onClickOutside = useCallback(
    (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleClose()
      }
    },
    [handleClose],
  )

  // Close modal when escape is pressed on keyboard
  const onEscapePress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    },
    [handleClose],
  )

  useEffect(() => {
    // Attach event listeners
    if (dismissable) {
      document.addEventListener('keydown', onEscapePress, true)
      if (dismissableViaOutsideClick) {
        document.addEventListener('click', onClickOutside, true)
      }
    }
    // Cleanup event listeners when unmounting component
    return () => {
      document.removeEventListener('keydown', onEscapePress, true)
      document.removeEventListener('click', onClickOutside, true)
    }
  }, [dismissable, dismissableViaOutsideClick, onEscapePress, onClickOutside])

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isVisible && (
        <FocusTrap
          active={isVisible}
          focusTrapOptions={{ escapeDeactivates: dismissable ? true : false }}
        >
          <Container
            key='overlay'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <StyledModal
              key='modal'
              ref={modalRef}
              maxWidth={maxWidth}
              initial={{ opacity: 0, scale: 1, y: -50 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 1, y: -200 }}
              transition={{ type: 'linear' }}
            >
              {children}
            </StyledModal>
          </Container>
        </FocusTrap>
      )}
    </AnimatePresence>,
    renderLocation,
  )
}

// Background overlay
const Container = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const StyledModal = styled(motion.div)<{ maxWidth?: number }>`
  width: 100%;
  max-width: ${(props) => props.maxWidth}px;
  max-height: 100vh;
  ${({ theme }) => theme.elevation.depth2};
`

export default Modal
