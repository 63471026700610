import React, {useEffect} from 'react'
import Header from './components/Header'
import PropertiesPanel from './components/PropertiesPanel'
import styled, {ThemeProvider} from 'styled-components'
import theme from './styles/defaultTheme'
import GlobalStyle from './styles/GlobalStyle'
import Stack from './components/Stack'
import ZoomControls from './components/ZoomControls'
import Editor from './components/Editor'
import {addNotification, useNotification} from './state/notifications.state'
import ToastContainer from './components/ToastContainer'
import ReactAppzi from 'react-appzi'
import {configure, GlobalHotKeys} from 'react-hotkeys'
import {keyMapping} from './keyMapping'
import {useDropzone} from 'react-dropzone'
import {useStageState} from './state/stage.state'
import {useFileReaderHelper} from './hooks/useFileReader'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import AnnouncementBar from "./components/AnnouncementBar";

const App = () => {
    const toastState = useNotification()

    const stageState = useStageState()
    let activeNode = stageState.activeNode
    let fileReader = useFileReaderHelper()

    const processInsertedImage = (file: File) => {
        const allowedMimeTypes = ['image/jpeg', 'image/png']

        // Do something with the files
        if (activeNode !== undefined) {
            //Check for allowed mime-types
            if (!allowedMimeTypes.includes(file.type)) {
                addNotification({
                    message:
                        'Can\'t process file, we currently only support jpg and png files',
                })
                return
            }

            fileReader.readAsDataURL(file)
        }
    }

    const onDrop = (files: any) => {
        if (files.length === 0) {
            return
        }

        processInsertedImage(files[0])
    }

    //Try to get imagefile at pasting
    document.onpaste = (pasteEvent: ClipboardEvent) => {
        if (pasteEvent.clipboardData === null) {
            return
        }

        const firstClipboardItem = pasteEvent.clipboardData.items[0].getAsFile()

        if (firstClipboardItem === null) {
            return
        }

        if (firstClipboardItem.type.indexOf('image') === -1) {
            return
        }

        processInsertedImage(firstClipboardItem)
    }


    // Initialize Appzi survey so it can be triggered from different places
    useEffect(() => {
        ReactAppzi.initialize('q23LN')
    }, [])

    //Configure hotkeys
    useEffect(() => {
        configure({
            ignoreRepeatedEventsWhenKeyHeldDown: false,
        })
    }, [])

    // Dropzone props
    const {getRootProps} = useDropzone({onDrop})
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <GlobalStyle/>
                <GlobalHotKeys keyMap={keyMapping} allowChanges={true}>
                    <Studio direction='vertical' gap={0} {...getRootProps()}>
                        <AnnouncementBar announcementId="20232710">
                            Help us build Mock Magic 2.0
                            – <a href="https://tally.so/r/w7RoJ6" target="_blank" rel="noreferrer">Join the waiting
                            list</a>
                        </AnnouncementBar>
                        <Header/>
                        <Main align='stretch' gap={0}>
                            <CanvasFrame distribute='center'>
                                <ToastContainer toasts={toastState}/>
                                <ZoomControls/>
                                <Route path='(/create)?/:device?/:variant?'>
                                    <Editor/>
                                </Route>
                            </CanvasFrame>
                            <PropertiesPanel/>
                        </Main>
                    </Studio>
                </GlobalHotKeys>
            </ThemeProvider>
        </Router>
    )
}

const Studio = styled(Stack)`
  outline: none;
`

const Main = styled(Stack)`
  height: 100%;
`

const CanvasFrame = styled(Stack)`
  flex: 1;
  position: relative;
  max-height: calc(100vh - 73px);
  overflow: hidden;
`

export default App
