import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path clipRule="evenodd" d="M14.354 3.646a.5.5 0 010 .708l-7.5 7.5a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.793l7.146-7.147a.5.5 0 01.708 0z" fill="currentColor" fillRule="evenodd" /></svg>;
});
const SvgCheckmark = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgCheckmark;