import React from 'react'
import styled from 'styled-components'
import {AnimatePresence, AnimateSharedLayout, motion} from 'framer-motion'
import {dismissNotification, Notification} from '../state/notifications.state'

interface ToastContainerProps {
    toasts: Notification[]
}

const ToastContainer: React.FC<ToastContainerProps> = (props) => {
    const {toasts} = props

    return (
        <AnimateSharedLayout>
            <ToastList layout>
                <AnimatePresence>
                    {toasts &&
                        toasts.map((toast) => {
                            return (
                                <Toast
                                    key={toast.id}
                                    id={toast.id}
                                    message={toast.message}
                                    autohide={toast.autohide}
                                    delay={toast.delay}
                                />
                            )
                        })}
                </AnimatePresence>
            </ToastList>
        </AnimateSharedLayout>
    )
}

const ToastList = styled(motion.div)`
  position: fixed;
  bottom: 16px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

type ToastProps = Notification

const Toast: React.FC<ToastProps> = (props) => {
    const {message, id, autohide, delay} = props

    React.useEffect(() => {
        if (!autohide) return

        // Dismiss notification after set time
        let timer = setTimeout(
            () => dismissNotification(id),
            delay ? delay * 1000 : 5000,
        )

        return () => {
            clearTimeout(timer)
        }
    }, [autohide, delay, id])

    // @ts-ignore
    return (
        <StyledToast
            aria-live="polite"
            role="status"
            layoutId={id?.toString()}
            initial={{opacity: 0, y: 32, scale: 0.8}}
            animate={{opacity: 1, y: 0, scale: 1}}
            onClick={() => dismissNotification(id)}
            exit={{
                opacity: 0,
                scale: 0.5,
                transition: {
                    type: 'spring',
                    damping: 20,
                    stiffness: 300,
                    restDelta: 1,
                    restSpeed: 1,
                },
            }}
            transition={{
                type: 'spring',
                damping: 24,
                stiffness: 200,
            }}
        >
            {message}
        </StyledToast>
    )
}

const StyledToast = styled(motion.div)`
  ${({theme}) => theme.type.UILarge};
  ${({theme}) => theme.elevation.depth2};
  background-color: ${({theme}) => theme.color.background1};
  color: ${({theme}) => theme.color.text1};
  white-space: nowrap;
  text-align: center;
  padding: 16px 24px;
  border-radius: 16px;
  pointer-events: all;
  //display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 16px;
  cursor: pointer;
`

export default ToastContainer
