import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path clipRule="evenodd" d="M11.889 11.89a.5.5 0 01-.707 0L8 8.706 4.818 11.89a.5.5 0 11-.707-.707L7.293 8 4.11 4.818a.5.5 0 11.707-.707L8 7.293l3.182-3.182a.5.5 0 11.707.707L8.707 8l3.182 3.182a.5.5 0 010 .707z" fill="currentColor" fillRule="evenodd" /></svg>;
});
const SvgClose = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgClose;