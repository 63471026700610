import PanelSection from '../PanelSection'
import Type from '../Type'
import DesignUploadButton from '../DesignUploadButton'
import {
  DeviceOrientation,
  setStageAndSetRerender,
  useStageState,
} from '../../state/stage.state'
import Stack from '../Stack'
import styled from 'styled-components'
import DesignCropButton from '../DesignCropButton'
import UIIconButton from '../UIIconButton'
import Icon from '../icons'

const UploadDesignSection = () => {
  const stageState = useStageState()
  const activeNode = stageState.activeNode
  const activeVariant = activeNode && activeNode.attributes.variant
  const designFile = activeNode && activeNode.attributes.designFileContents

  let fileUploaded = false
  if (designFile) {
    fileUploaded = true
  }

  let designDimensionString
  if (activeVariant && activeVariant.getViewportDimensions().width) {
    const viewportDimensions = activeVariant.getViewportDimensions()
    designDimensionString =
      viewportDimensions &&
      `${viewportDimensions.width}x${viewportDimensions.height} pixels`
  }

  const deleteDesign = () => {
    if (activeNode === undefined) {
      return
    }
    activeNode.attributes.deviceOrientation = DeviceOrientation.Portrait
    activeNode.attributes.originalDesignFileContents = undefined
    activeNode.attributes.designFileContents = undefined
    setStageAndSetRerender({ activeNode: activeNode })
  }

  return (
    <PanelSection title={fileUploaded ? 'Your design' : 'Add your design'}>
      <Content direction="vertical" gap={8} align="stretch">
        {designFile && (
          <UploadedImages>
            <ImageContainer>
              <StyledUIIconButton
                onClick={() => deleteDesign()}
                aria-label="Delete design"
                className="design-remove"
              >
                <Icon name="Close" />
              </StyledUIIconButton>
              <img src={designFile} alt="Uploaded design" />
            </ImageContainer>
          </UploadedImages>
        )}

        <Stack gap={8} align="stretch">
          <DesignUploadButton />
          {fileUploaded && <DesignCropButton />}
        </Stack>

        <div>
          <Type variant="UISmall">Supported: png, jpg.</Type>
          {!fileUploaded && (
            <Type variant="UISmall">
              drop your file, paste or click button above
            </Type>
          )}
          {designDimensionString && (
            <Type variant="UISmall">
              Viewport size: {designDimensionString}.
            </Type>
          )}
        </div>
      </Content>
    </PanelSection>
  )
}

const Content = styled(Stack)`
  margin-top: 8px;
  text-align: center;
  color: ${({ theme }) => theme.color.text3};
`

const UploadedImages = styled.div`
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 8px;
  margin-bottom: 12px;
`

const StyledUIIconButton = styled(UIIconButton)`
  position: absolute;
  right: 0;
  top: 0;
  fill: red;
  font-size: 16px;
`

const ImageContainer = styled.div`
  position: relative;
  padding: 8px;
  img {
    width: auto;
    height: auto;
    max-height: 80px;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
`

export default UploadDesignSection
