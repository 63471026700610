import React from 'react'
import styled from 'styled-components'
import Stack from './Stack'
import { DeviceOrientation } from '../state/stage.state'
import { AnimateSharedLayout, motion } from 'framer-motion'
import Icon from './icons'
import { IconNames } from './icons/template/IconType'

interface Segment {
  id: DeviceOrientation
  icon: IconNames
  label: string
  rotate?: number
}

interface Props {
  segments: Segment[]
  showLabels: boolean
  handleChange: any
  activeValue: any
  typeLabel?: string
}

const SegmentedControl: React.FC<Props> = (props) => {
  const { segments, handleChange, activeValue, typeLabel } = props

  return (
    <AnimateSharedLayout>
      <Wrapper direction="vertical" gap={4}>
        <Controls align="center" gap={4}>
          {segments.map((segment) => (
            <StyledSegment
              key={segment.id}
              active={segment.id === activeValue}
              onClick={() => handleChange(segment.id)}
              id={`segmented-control-${typeLabel}.${segment.label}`}
            >
              {segment.id === activeValue && (
                <SegmentBackground
                  layoutId="underline"
                  initial={false}
                  transition={{
                    type: 'spring',
                    stiffness: 500,
                    damping: 50,
                    bounce: 0,
                  }}
                />
              )}

              <StyledIcon name={segment.icon} rotate={segment.rotate || 0} />
            </StyledSegment>
          ))}
        </Controls>
        <Labels distribute="spaceEvenly" gap={4}>
          {segments.map((segment) => (
            <Label key={segment.id} onClick={() => handleChange(segment.id)}>
              {segment.label}
            </Label>
          ))}
        </Labels>
      </Wrapper>
    </AnimateSharedLayout>
  )
}

const Wrapper = styled(Stack)`
  width: 100%;
`

const StyledIcon = styled(Icon)<{ rotate: number }>`
  transform: rotate(${(props) => props.rotate}deg);
  font-size: 16px;
`

const Controls = styled(Stack)`
  width: 100%;
  padding: 3px;
  background-color: ${({ theme }) => theme.color.background2};
  border-radius: 8px;
  overflow: hidden;
`

const StyledSegment = styled.button<{
  active?: boolean
  id?: string
  class?: string
}>`
  position: relative;
  outline: 0;
  cursor: pointer;
  padding: 4px;
  text-align: center;
  align-self: stretch;
  flex-grow: 1;
  transition: all 0.2s ease-in-out;
  & svg {
    margin: 0 auto;
    display: block;
    color: ${(props) =>
      props.active ? props.theme.color.actionDark : props.theme.color.text3};
    transition: all 0.2s ease-in-out;
  }
  &:hover,
  &:focus {
    & svg {
      color: ${(props) =>
        props.active ? props.theme.color.actionDark : props.theme.color.text1};
    }
  }
`

const SegmentBackground = styled(motion.div)`
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 0 10px rgb(0 0 0 / 12%);
  background-color: ${({ theme }) => theme.color.background1};
`

const Labels = styled(Stack)`
  width: 100%;
  padding: 2px;
`

const Label = styled.div`
  ${({ theme }) => theme.type.UISmall};
  cursor: pointer;
  align-self: stretch;
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.color.text2};
`

export default SegmentedControl
