import React from 'react'
import styled from 'styled-components'
import Stack from './Stack'

interface Props {
  label: string
  alignLabel?: 'start' | 'center'
  labelFromTop?: number
}

const Property: React.FC<Props> = (props) => {
  const { label, children, alignLabel, labelFromTop } = props

  return (
    <StyledProperty
      gap={8}
      direction="horizontal"
      align={alignLabel ? alignLabel : 'center'}
    >
      <Label labelFromTop={labelFromTop}>{label}</Label>
      {children}
    </StyledProperty>
  )
}

const StyledProperty = styled(Stack)`
  margin-bottom: 12px;
  min-height: 30px;
  &:last-child {
    margin-bottom: 0;
  }
`

const Label = styled.div<{ labelFromTop?: number }>`
  color: ${({ theme }) => theme.color.text1};
  flex-shrink: 0;
  width: 64px;
  position: relative;
  top: ${(props) => (props.labelFromTop ? `${props.labelFromTop}px` : 0)};
`

export default Property
