import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    overscroll-behavior-x: none;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    ${normalize}
    min-height: 100%;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.6rem;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    padding: 0;
    margin: 0;
    overflow: visible;
    text-transform: none;
    background-color: transparent;
    border: 0;
    color: inherit;
    & svg {
      display: block;
    }
  }

  #root {
    flex: 1;
    display: flex;
    flex-direction: row;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`

export default GlobalStyle
