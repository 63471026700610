import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M5.377.859l-.333 2.33A2.5 2.5 0 003.5 5.5v5a2.5 2.5 0 001.544 2.31l.333 2.331a1 1 0 00.99.859h3.266a1 1 0 00.99-.859l.333-2.33a2.504 2.504 0 001.503-1.857.5.5 0 00.291-.454v-5a.5.5 0 00-.291-.454 2.504 2.504 0 00-1.503-1.857L10.623.86A1 1 0 009.633 0H6.367a1 1 0 00-.99.859zM4.5 5.5A1.5 1.5 0 016 4h4a1.5 1.5 0 011.5 1.5v5A1.5 1.5 0 0110 12H6a1.5 1.5 0 01-1.5-1.5v-5z" fill="currentColor" /><path d="M12.4 5.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5v-2z" fill="currentColor" /></svg>;
});
const SvgAppleWatchUltra = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgAppleWatchUltra;