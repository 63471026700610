import { createReduxModule } from 'hooks-for-redux'
import { IDeviceData } from '../assets/devices'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'

interface IDocument {
  name?: string,
  renderer?: PIXI.Application,
  viewport?: Viewport
}

export const initialState: IDocument = {
  name: 'Untitled-mockup',
}

export function getActiveDevice(devices: IDeviceData[], deviceId: string) {
  return devices.find(device => device.id === deviceId)
}

export function getActiveDeviceVariant(device: IDeviceData, variantId: string) {
  return device.variants.find(variant => variant.id === variantId)
}

export const [useDocumentState, { setDocument, clearDocument }] = createReduxModule('document', initialState, {
  setDocument: (state, partialState: IDocument) => ({ ...state, ...partialState }),
  clearDocument: () => initialState,
})
