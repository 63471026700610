import React, {useCallback, useRef, useState} from 'react'
import {DeviceOrientation, setStageAndSetRerender, useStageState,} from '../state/stage.state'
import styled from 'styled-components'
import {useModal} from '../hooks/useModal'
import Modal from './Modal'
import ReactCrop, {Crop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {Button} from './Button'
import Stack from './Stack'
import UIButton from './UIButton'
import UIIconButton from './UIIconButton'
import Type from './Type'
import Icon from './icons'
import { GlobalHotKeys } from 'react-hotkeys'

const DesignCropButton: React.FC = () => {
    const stageState = useStageState()
    let activeNode = stageState.activeNode

    const {
        isVisible: cropModalIsVisible,
        show: showCropModal,
        hide: hideCropModal,
    } = useModal()

    const [crop, setCrop] = useState<Crop>({})

    interface CompletedCrop {
        aspect?: number | undefined
        x: number
        y: number
        width: number
        height: number
        unit?: 'px' | '%' | undefined
    }

    function getCroppedImg(image: HTMLImageElement, crop: CompletedCrop) {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const ctx = canvas.getContext('2d')

        if (ctx === null) {
            return
        }

        const pixelRatio = window.devicePixelRatio
        canvas.width = crop.width * scaleX * pixelRatio
        canvas.height = crop.height * scaleY * pixelRatio
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        )

        // As Base64 string
        return canvas.toDataURL('image/jpeg')
    }

    const applyCrop = () => {
        if (activeNode === undefined) {
            return
        }

        if (activeNode.attributes.originalDesignFileContents === undefined) {
            return
        }

        const designImage = imgRef.current
        if (designImage === undefined) {
            return
        }

        activeNode.attributes.designFileContents = getCroppedImg(
            designImage,
            crop as CompletedCrop,
        )

        //Set applied crop values, so next time the modal inits, this values will be used
        activeNode.attributes.designCropValues = {
            x: crop.x ?? 0,
            y: crop.y ?? 0,
            width: crop.width ?? 0,
            height: crop.height ?? 0,
            basedOnFullSizeImage: false,
        }

        setStageAndSetRerender({activeNode: activeNode})
    }

    const imgRef = useRef<HTMLImageElement>()

    const onImageLoad = useCallback(
        (img) => {
            imgRef.current = img

            if (activeNode?.attributes.variant === undefined) {
                return false
            }

            const stateValues = activeNode.attributes.designCropValues

            if (
                stateValues.basedOnFullSizeImage &&
                (img.naturalWidth > img.width || img.naturalHeight > img.height)
            ) {
                //Scale down
                const scaleX = img.width / img.naturalWidth
                const scaleY = img.height / img.naturalHeight

                stateValues.x = scaleX * stateValues.x
                stateValues.y = scaleY * stateValues.y
                stateValues.width = scaleX * (stateValues.width ?? 0)
                stateValues.height = scaleY * (stateValues.height ?? 0)
            }

            const aspect = activeNode.attributes.variant.getDesignAspect(
                activeNode.attributes.deviceOrientation ?? DeviceOrientation.Portrait,
            )
            const width =
                stateValues.width ??
                (img.width / aspect < img.height * aspect
                    ? img.width
                    : img.height * aspect)
            const height =
                stateValues.height ??
                (img.width / aspect > img.height * aspect
                    ? img.height
                    : img.width / aspect)

            const x = stateValues.x ?? 0
            const y = stateValues.y ?? 0

            setCrop({
                unit: 'px',
                width,
                height,
                x,
                y,
                aspect,
            })

            return false
        },
        [
            activeNode?.attributes.deviceOrientation,
            activeNode?.attributes.variant,
            activeNode?.attributes.designCropValues,
        ],
    )

    const hotKeyHandlers = {
        crop_design: (event: any) => {
            event.preventDefault()
            showCropModal();
        },
    }

    return (
        <>
            <GlobalHotKeys handlers={hotKeyHandlers} allowChanges={true}>
                <CropButton onClick={() => showCropModal()} title="Crop image">
                    <Icon name="Crop" size={16}/>
                </CropButton>
            </GlobalHotKeys>

            <Modal
                isVisible={cropModalIsVisible}
                handleClose={() => hideCropModal()}
                maxWidth={800}
                dismissableViaOutsideClick={false}
            >
                <ModalContainer>
                    <ModalHeader>
                        <Stack distribute="spaceBetween" align="center">
                            <Type variant="label">Crop image</Type>
                            <UIIconButton onClick={() => hideCropModal()}>
                                <Icon name="Close" size={20}/>
                            </UIIconButton>
                        </Stack>
                    </ModalHeader>
                    <ModalBody>
                        <ReactCrop
                            src={
                                stageState.activeNode?.attributes.originalDesignFileContents ??
                                ''
                            }
                            crop={crop}
                            onImageLoaded={onImageLoad}
                            onChange={(newCrop) => setCrop(newCrop)}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Stack gap={16} distribute="end">
                            <Button variant="secondary" onClick={() => hideCropModal()}>
                                Cancel
                            </Button>
                            <Button
                                onClick={function () {
                                    applyCrop()
                                    hideCropModal()
                                }}
                            >
                                Apply
                            </Button>
                        </Stack>
                    </ModalFooter>
                </ModalContainer>
            </Modal>
        </>
    )
}

const ModalContainer = styled.div`
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  height: 100%;
`

const ModalBody = styled.div`
  height: 100%;
  max-height: 80vh;
  padding: 16px;
  overflow-y: scroll;
  text-align: center;
`

const ModalHeader = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 16px 12px 16px 16px;
  background-color: #fff;
  border-bottom: 1px solid ${({theme}) => theme.color.border};
`

const ModalFooter = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid ${({theme}) => theme.color.border};
`

const CropButton = styled(UIButton)`
  width: auto;
`

export default DesignCropButton
