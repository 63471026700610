import React from 'react'
import styled from 'styled-components'

type Direction = 'horizontal' | 'vertical'
type Distribute =
  | 'start'
  | 'center'
  | 'end'
  | 'spaceBetween'
  | 'spaceAround'
  | 'spaceEvenly'

type Align = 'start' | 'center' | 'end' | 'stretch'
type Gap = number

type Sizes = 'sm' | 'md' | 'lg'

interface FlexProps {
  direction?: Direction
  distribute?: Distribute
  align?: Align
  gap?: Gap
}

type SizeProps = {
  [key in Sizes]?: FlexProps
}

interface ResponsiveProps extends FlexProps, SizeProps {
  children: React.ReactNode
  responsive?: {
    [key: number]: FlexProps
  }
}

const Stack = React.forwardRef<HTMLDivElement, ResponsiveProps>(
  (
    {
      direction = 'horizontal',
      distribute = 'start',
      align = 'start',
      gap = 16,
      children,
      responsive,
      ...restProps
    },
    ref,
  ) => (
    <StyledStack
      ref={ref}
      direction={direction}
      distribute={distribute}
      align={align}
      gap={gap}
      responsive={responsive}
      {...restProps}
    >
      {children}
    </StyledStack>
  ),
)

// Convert Distribute values to valid CSS values
function convertDistribute(value: Distribute) {
  switch (value) {
    case 'start':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'end':
      return 'flex-end'
    case 'spaceBetween':
      return 'space-between'
    case 'spaceAround':
      return 'space-around'
    case 'spaceEvenly':
      return 'space-evenly'
    default:
      return 'flex-start'
  }
}

// Convert Align values to valid CSS values
function convertAlign(value: Align) {
  switch (value) {
    case 'start':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'end':
      return 'flex-end'
    case 'stretch':
      return 'stretch'
    default:
      return 'flex-start'
  }
}

function createStyles(
  props: FlexProps,
  fallBackDirection?: Direction,
  fallbackGap?: Gap,
) {
  // Declare direction and gap if not set (e.g. in responsive prop)
  // A direction and gap is required to correctly calculate the required margins
  // For the 'responsive' prop this direction isn't always set, so this
  // fallBackDirection should be passed from the 'main' props to calculate the correct margins.
  props.direction === undefined &&
    fallBackDirection &&
    (props.direction = fallBackDirection)

  props.gap === undefined && fallbackGap && (props.gap = fallbackGap)

  const styles = `
  ${
    props.direction
      ? `flex-direction: ${
          props.direction === 'horizontal' ? 'row' : 'column'
        };`
      : ''
  }
  ${
    props.distribute
      ? `justify-content: ${convertDistribute(props.distribute)};`
      : ''
  }
  ${props.align ? `align-items: ${convertAlign(props.align)};` : ''}

  & > * {
    ${
      props.direction === 'horizontal' && props.gap
        ? `
      margin-right: ${props.gap + 'px'};
      margin-bottom: unset;
      &:last-child {
        margin-right: 0px;
      }
    `
        : ''
    }
    ${
      props.direction === 'vertical' && props.gap
        ? `
      margin-bottom: unset;
      margin-bottom: ${props.gap + 'px'};
      &:last-child {
        margin-bottom: 0px;
      }
    `
        : ''
    };

  }
  `

  return styles
}

function createMediaQueries(
  responsiveProps: any,
  fallBackDirection: Direction,
  fallBackGap: Gap,
) {
  let mergedStyles = ''

  // Create media queries and styles for each defined size in the 'responsive'-prop
  for (const size in responsiveProps) {
    const styles = `
    @media only screen and (min-width: ${size + 'px'}) {
      ${createStyles(responsiveProps[size], fallBackDirection, fallBackGap)}
    }
  `
    mergedStyles += styles
  }

  return mergedStyles
}

const StyledStack = styled.div<ResponsiveProps>`
  display: flex;
  width: 100%;
  ${(props) => createStyles(props)};
  ${(props) =>
    props.responsive &&
    createMediaQueries(props.responsive, props.direction!, props.gap!)};
`

export default Stack
