import { CSSObject, DefaultTheme } from 'styled-components'

type TypeVariants =
  | 'display'
  | 'label'
  | 'UIHeading'
  | 'UIHeadingSmall'
  | 'UILarge'
  | 'UIRegular'
  | 'UISmall'

type Colors =
  | 'action'
  | 'actionDark'
  | 'background1'
  | 'background2'
  | 'background3'
  | 'glass'
  | 'text1'
  | 'text2'
  | 'text3'
  | 'textInverse'
  | 'border'
  | 'borderDark'

type Elevation = 'depth1' | 'depth2'

declare module 'styled-components' {
  export interface DefaultTheme {
    type: {
      [T in TypeVariants]: CSSObject
    }
    color: {
      [T in Colors]: string
    }
    elevation: {
      [T in Elevation]: string
    }
  }
}

const theme: DefaultTheme = {
  type: {
    display: {
      'font-size': '1.8rem',
      'line-height': '2.4rem',
      'font-weight': '600',
    },
    label: {
      'font-size': '1.4rem',
      'line-height': '2rem',
      'font-weight': '500',
    },
    UIHeading: {
      'font-size': '1.2rem',
      'line-height': '1.8rem',
      'font-weight': '600',
    },
    UIHeadingSmall: {
      'font-size': '1.1rem',
      'line-height': '1.8rem',
      'font-weight': '600',
    },
    UILarge: {
      'font-size': '1.4rem',
      'line-height': '2rem',
      'font-weight': '400',
    },
    UIRegular: {
      'font-size': '1.2rem',
      'line-height': '1.8rem',
      'font-weight': '400',
    },
    UISmall: {
      'font-size': '1.1rem',
      'line-height': '1.8rem',
      'font-weight': '400',
    },
  },
  color: {
    action: '#3378F6',
    actionDark: '#0A5AEA',
    background1: '#fff',
    background2: '#EBECEF',
    background3: '#DDDFE4',
    glass: 'rgb(255 255 255 / 90%)',
    text1: '#000',
    text2: '#3F4550',
    text3: '#6C7689',
    textInverse: '#fff',
    border: '#DDE0E3',
    borderDark: '#C1C7CC',
  },
  elevation: {
    depth1: 'box-shadow: 0 0 10px rgb(0 0 0 / 4%), 0 2px 4px rgb(0 0 0 / 8%);',
    depth2:
      'box-shadow: 0 0 10px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 12%);',
  },
}

export default theme
