import * as PIXI from 'pixi.js'
import { DisplayObject, Sprite } from 'pixi.js'
import { Viewport } from 'pixi-viewport'
import INode from './INode'

export default class Node implements INode {
  public id: string

  public renderObject?: DisplayObject | Sprite

  public attributes?: Object

  constructor() {
    if (this.constructor === Node) {
      throw new Error('Node is an abstract class which cannot be instantiated')
    }

    this.id = `node_${Math.floor(Math.random() * 1000)}`
  }

  fitInViewport(canvas: Viewport): void {
    if (this.renderObject === undefined) {
      return
    }


  }

  destroy(canvas: Viewport): void {
  }

  render(canvas: Viewport, renderer: PIXI.Application): void {
  }


}
