import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path clipRule="evenodd" d="M3.5.25A.25.25 0 013.75 0h8.5a.25.25 0 01.25.25v15.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25zm.75 1.5h7.5v12.5h-7.5zm2.875-1a.125.125 0 100 .25h1.75a.125.125 0 100-.25z" fill="currentColor" fillRule="evenodd" /></svg>;
});
const SvgHuaweiP8 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgHuaweiP8;