import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { v4 as uuid } from 'uuid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = useState(() => title ? uuid() : undefined);
  return <svg fill="none" height="1em" viewBox="0 0 16 16" width="1em" xmlns="http://www.w3.org/2000/svg" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g fill="currentColor"><path clipRule="evenodd" d="M5.5 0a2 2 0 00-2 2v12a2 2 0 002 2h5a2 2 0 002-2V2a2 2 0 00-2-2zm0 1a1 1 0 00-1 1v12a1 1 0 001 1h5a1 1 0 001-1V2a1 1 0 00-1-1z" fillRule="evenodd" /><circle cx={5.5} cy={2} r={0.5} /></g></svg>;
});
const SvgPixel5 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));
export default SvgPixel5;