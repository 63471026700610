import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Stack from './Stack'
import { useStageState } from '../state/stage.state'
import { ReactComponent as LoadIcon } from '../assets/icons/loading.svg'
import Icon from './icons'

interface Props {
  label: string
  onClick?: () => void
}

const DeviceInputField: React.FC<Props> = (props) => {
  const { label, onClick } = props
  const [showLoadingState, setShowLoadingState] = useState(false)

  const deviceFileIsLoading =
    useStageState()?.activeNode?.attributes.deviceFileIsLoading ?? false

  useEffect(() => {
    // Set a small delay to showing the loading icon
    let timer = setTimeout(
      () =>
        deviceFileIsLoading
          ? setShowLoadingState(true)
          : setShowLoadingState(false),
      300,
    )

    return () => {
      clearTimeout(timer)
    }
  }, [deviceFileIsLoading])

  return (
    <Input title={label} onClick={onClick}>
      <Stack gap={8} align="center">
        <Label>{label}</Label>
        {showLoadingState && <StyledLoadIcon />}
        <Icon name="Select" size={16} />
      </Stack>
    </Input>
  )
}

const Input = styled.button`
  ${({ theme }) => theme.type.UIRegular};
  width: 100%;
  max-width: 160px;
  text-align: left;
  outline: 0;
  cursor: pointer;
  padding: 5px 4px 5px 8px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  color: ${({ theme }) => theme.color.text1};
  & svg {
    fill: ${({ theme }) => theme.color.text3};
  }
  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.color.action};
  }
`

const Label = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledLoadIcon = styled(LoadIcon)`
  width: 16px;
  height: 16px;
  fill: #000;
  stop-color: #000;
`

export default DeviceInputField
