import ReactCrop, { makeAspectCrop } from 'react-image-crop'
import { IDesignCropValues } from '../nodes/DeviceNode'
import { DeviceOrientation } from '../state/stage.state'
import DeviceVariant from '../entities/DeviceVariant'

interface IcropResult {
  image: string,
  cropValues: IDesignCropValues
}

export default function cropDesignByDeviceVariant(designData: string, variant: DeviceVariant, deviceOrientation: DeviceOrientation): IcropResult | null {
  const designImage = new Image()
  designImage.src = designData

  const aspect = variant.getDesignAspect(deviceOrientation ?? DeviceOrientation.Portrait)
  const width = designImage.width / aspect < designImage.height * aspect || designImage.height * aspect > designImage.width ? designImage.width : designImage.height * aspect
  const height = designImage.width / aspect > designImage.height * aspect ? designImage.height : designImage.width / aspect

  const x = 0
  const y = 0

  const crop: ReactCrop.Crop = {
    unit: 'px',
    width,
    height,
    x,
    y,
    aspect,
  }

  const cropValues = makeAspectCrop(crop, width, height)

  const canvas = document.createElement('canvas')
  const scaleX = designImage.naturalWidth / designImage.width
  const scaleY = designImage.naturalHeight / designImage.height
  const ctx = canvas.getContext('2d')

  if (ctx === null) {
    return null
  }

  if (cropValues === undefined ||
    cropValues.width === undefined ||
    cropValues.height === undefined ||
    cropValues.x === undefined ||
    cropValues.y === undefined) {
    return null
  }

  const pixelRatio = window.devicePixelRatio
  canvas.width = cropValues.width * scaleX * pixelRatio
  canvas.height = cropValues.height * scaleY * pixelRatio
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    designImage,
    cropValues.x * scaleX,
    cropValues.y * scaleY,
    cropValues.width * scaleX,
    cropValues.height * scaleY,
    0,
    0,
    cropValues.width * scaleX,
    cropValues.height * scaleY,
  )

  const imageContents = canvas.toDataURL('image/jpeg')

  return {
    image: imageContents,
    cropValues: {
      x: cropValues.x,
      y: cropValues.y,
      width: cropValues.width,
      height: cropValues.height,
      basedOnFullSizeImage: true,
    },
  }
}
