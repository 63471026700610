import React from 'react'
import styled from 'styled-components'
import PopOver from './PopOver'
import Stack from './Stack'

interface MenuLink {
  label: string
  keyCommand?: string
  action: () => void
}

interface Props {
  children: React.ReactElement
  contents: MenuLink[]
  width?: number
  placement?:
    | 'bottom-center'
    | 'bottom-start'
    | 'bottom-end'
    | 'top-start'
    | 'top-center'
    | 'top-end'
    | 'left-end'
    | 'left-center'
    | 'left-start'
    | 'right-end'
    | 'right-center'
    | 'right-start'
    | 'center'
  autoPlacement?: boolean
}

const PopOverMenu: React.FC<Props> = (props) => {
  const { children, contents, width, placement, autoPlacement } = props

  const items = (
    <Stack direction="vertical" gap={0}>
      {contents.map((item) => (
        <MenuItem key={item.label} onClick={item.action}>
          <Stack gap={8} distribute="spaceBetween">
            <span>{item.label}</span>
            {item.keyCommand && (
              <span className="keyCommand">{item.keyCommand}</span>
            )}
          </Stack>
        </MenuItem>
      ))}
    </Stack>
  )

  return (
    <PopOver
      contents={<Content>{items}</Content>}
      autoPlacement={autoPlacement}
      placement={placement || 'bottom-start'}
      width={width}
      hideArrow
      initialFocus="#popOverContainer"
    >
      {children}
    </PopOver>
  )
}

const Content = styled.div`
  padding: 4px 0;
  background-color: ${({ theme }) => theme.color.glass};
  backdrop-filter: blur(10px);
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 8px;
`

const MenuItem = styled.button`
  padding: 12px 12px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
  outline: 0;
  color: ${({ theme }) => theme.color.text1};
  .keyCommand {
    color: ${({ theme }) => theme.color.text3};
    text-align: left;
    width: 24px;
  }
  &:hover,
  &:focus {
    background-color: rgb(0 0 0 / 10%)};
  }
`

export default PopOverMenu
