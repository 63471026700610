import React from 'react'
import styled from 'styled-components'
import Stack from './Stack'

interface Props {
  title: string
  borderTop?: boolean
  borderBottom?: boolean
}

const PanelSection: React.FC<Props> = (props) => {
  const { title, children, borderTop, borderBottom } = props
  return (
    <Section borderTop={borderTop || false} borderBottom={borderBottom || true}>
      <HeaderRow gap={8} align="center">
        <Title>{title}</Title>
      </HeaderRow>

      {children}
    </Section>
  )
}

const Section = styled.div<{ borderTop: boolean; borderBottom: boolean }>`
  padding: 12px 16px 16px 16px;
  ${({ theme }) => theme.type.UIRegular};
  border-bottom: ${(props) =>
    props.borderBottom ? `1px solid ${props.theme.color.background2}` : '0'};
  border-top: ${(props) =>
    props.borderTop ? `1px solid ${props.theme.color.background2}` : '0'};
`

const Title = styled.div`
  ${({ theme }) => theme.type.UIHeading};
  flex: 1;
`

const HeaderRow = styled(Stack)`
  margin-bottom: 2px;
`

export default PanelSection
