import {Viewport} from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import {setStage} from '../state/stage.state'

export default class PreRenderer {

    private readonly pixi: PIXI.Application

    constructor(PixiObject: PIXI.Application) {
        this.pixi = PixiObject
    }

    addViewport(wrapperElement: HTMLElement) {
        const viewport = new Viewport({
            screenWidth: wrapperElement.clientWidth,
            screenHeight: wrapperElement.clientHeight,
            worldWidth: wrapperElement.clientWidth,
            worldHeight: wrapperElement.clientHeight,
            divWheel: wrapperElement,
            passiveWheel: false,
            stopPropagation: true,
            interaction: this.pixi.renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
        })

        //Add navigation
        viewport.drag({
            ignoreKeyToPressOnTouch: true,
        })
            .wheel({
                percent: 2,
                trackpadPinch: true,
                wheelZoom: false,
            })
            .decelerate()
            .pinch()
            .clampZoom({
                minScale: 0.05,
                maxScale: 3.0
            });

        viewport.on('zoomed-end', (event) => {
            const zoomPercentage = Math.ceil(event.lastViewport.scaleX * 100)
            setStage({zoomLevel: zoomPercentage})
        })

        viewport.name = 'viewport'
        this.pixi.stage.addChild(viewport)

        window.onresize = () => {
            this.pixi.renderer.resize(
                wrapperElement.clientWidth,
                wrapperElement.clientHeight,
            )

            viewport.resize(
                wrapperElement.clientWidth,
                wrapperElement.clientHeight,
                wrapperElement.clientWidth,
                wrapperElement.clientHeight,
            )
        }

        /**
         *
         * TODO: maybe refactor this..
         *
         */
        window.onkeydown = (e) => {
            if (e.key === 'Meta') {
                viewport.wheel({
                    percent: 2,
                    trackpadPinch: true,
                    wheelZoom: true,
                })
            }
        }

        window.onkeyup = (e) => {
            if (e.key === 'Meta') {
                viewport.wheel({
                    percent: 2,
                    trackpadPinch: true,
                    wheelZoom: false,
                })
            }
        }

        return viewport
    }

    render(wrapperElement: HTMLElement) {
        this.pixi.renderer.resize(wrapperElement.clientWidth, wrapperElement.clientHeight)

        wrapperElement.appendChild(this.pixi.view)
        this.pixi.start()

        return this.pixi
    }


}
