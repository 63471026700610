import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

interface Props {
  type?: 'button' | 'submit' | 'reset' | undefined
  variant?: 'primary' | 'secondary'
}

export const Button = styled(motion.button)<Props>`
  ${({ theme }) => theme.type.label}
  cursor: pointer;
  display: block;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.action};
  color: ${({ theme }) => theme.color.textInverse};
  transition: all 0.2s ease-in-out;
  outline: 0;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.actionDark};
  }
  ${(props) =>
    props.variant === 'secondary' &&
    css`
      background-color:  ${({ theme }) => theme.color.background2};
      color: ${({ theme }) => theme.color.text1};
      &:hover,
      &:focus {
        background-color: ${({ theme }) => theme.color.background3};
      }
    `}
`
