import React from 'react'
import styled from 'styled-components'
import FeedbackSection from './propertiesPanelSections/FeedbackSection'
import Stack from './Stack'
import DeviceNodeProperties from './propertiesPanelSections/nodePropertiesSections/DeviceNodeProperties'

const PropertiesPanel: React.FC = () => {
  return (
    <Panel distribute="spaceBetween" direction="vertical">
      <DeviceNodeProperties />
      <FeedbackSection />
    </Panel>
  )
}

const Panel = styled(Stack)`
  border-left: 1px solid ${({ theme }) => theme.color.background2};
  width: 264px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background1};
`

export default PropertiesPanel
