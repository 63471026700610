import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import Tooltip from './Tooltip'

interface Props {
  active?: boolean
  name: string
  color: string
  light?: boolean
  onClick?: () => void
}

const DeviceVariantColor: React.FC<Props> = (props) => {
  const { active, name, color, light, onClick } = props
  return (
    <Tooltip value={name}>
      <Color
        whileTap={{ scale: 0.8 }}
        active={active}
        color={color}
        light={light ? light : false}
        onClick={onClick}
        id={`variant-${name}`}
        className="device-variant-button"
      >
        <Swatch color={color} />
      </Color>
    </Tooltip>
  )
}

const Color = styled(motion.button)<{
  active?: boolean
  color: string
  light?: boolean,
  id?: string
}>`
  outline: 0;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 5px;
  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.color.action};
  }
  ${(props) =>
  props.active &&
  `
    border-color: ${props.theme.color.borderDark};
  `}
`

const Swatch = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 14px;
  height: 14px;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 10%);
`

export default DeviceVariantColor
