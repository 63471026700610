import React, { useCallback, useEffect, useState } from 'react'
import { useLayer, Arrow } from 'react-laag'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import FocusTrap from 'focus-trap-react'

interface Props {
  children: React.ReactElement
  contents: React.ReactElement
  placement?:
    | 'bottom-center'
    | 'bottom-start'
    | 'bottom-end'
    | 'top-start'
    | 'top-center'
    | 'top-end'
    | 'left-end'
    | 'left-center'
    | 'left-start'
    | 'right-end'
    | 'right-center'
    | 'right-start'
    | 'center'
    | undefined
  autoPlacement?: boolean
  hideArrow?: boolean
  width?: number
  initialFocus?: string | HTMLElement | (() => HTMLElement) | undefined
}

const PopOver: React.FC<Props> = (props) => {
  const {
    children,
    contents,
    placement,
    autoPlacement,
    hideArrow,
    width,
    initialFocus,
  } = props
  const [isOpen, setOpen] = useState(false)

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  // Close modal when escape is pressed on keyboard
  const onEscapePress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        close()
      }
    },
    [close],
  )

  const onClickClosePopOver = useCallback(
    (event) => {
      // Check if there was a click on an element that should close this popover
      if (event.target.closest('[data-closepopover]')) {
        close()
      }
    },
    [close],
  )

  useEffect(() => {
    // Attach event listener
    document.addEventListener('keydown', onEscapePress, false)
    document.addEventListener('click', onClickClosePopOver, false)

    // Cleanup event listener when unmounting component
    return () => {
      document.removeEventListener('keydown', onEscapePress, false)
      document.removeEventListener('click', onClickClosePopOver, false)
    }
  }, [onClickClosePopOver, onEscapePress])

  // Tooltip positioning
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOpen,
    onOutsideClick: close,
    placement: placement || 'bottom-center',
    auto: autoPlacement,
    triggerOffset: 12,
    arrowOffset: 16,
  })

  let trigger
  trigger = React.cloneElement(children, {
    ...triggerProps,
    onClick: () => setOpen(!isOpen),
  })

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <FocusTrap
              active={isOpen}
              focusTrapOptions={{
                initialFocus: initialFocus,
                allowOutsideClick: true,
              }}
            >
              <StyledPopOver
                id="popOverContainer"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.1 }}
                width={width}
                tabIndex={-1}
                {...layerProps}
              >
                {contents}
                {!hideArrow && (
                  <Arrow
                    {...arrowProps}
                    angle={50}
                    roundness={1}
                    borderWidth={1}
                    size={4}
                  />
                )}
              </StyledPopOver>
            </FocusTrap>
          )}
        </AnimatePresence>,
      )}
    </>
  )
}

const StyledPopOver = styled(motion.div)<{ width?: number }>`
  ${({ theme }) => theme.elevation.depth2};
  ${({ theme }) => theme.type.UILarge};
  overflow: hidden;
  border-radius: 8px;
  color: ${({ theme }) => theme.color.text1};
  z-index: 1000;
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
  outline: 0;
`

export default PopOver
