import styled from 'styled-components'

const UIIconButton = styled.button`
  background-color: ${({ theme }) => theme.color.background1};
  cursor: pointer;
  outline: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  & svg {
    fill: ${({ theme }) => theme.color.text2};
    transition: all 0.2s ease-in-out;
  }
  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.color.background3};
    & svg {
      fill: ${({ theme }) => theme.color.text1};
    }
  }
`

export default UIIconButton
