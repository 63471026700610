import React, {useCallback} from 'react'
import styled from 'styled-components'
import PopOverMenu from './PopOverMenu'
import Stack from './Stack'
import {setStage, useStageState} from '../state/stage.state'
import {motion} from 'framer-motion'
import {GlobalHotKeys} from 'react-hotkeys'
import Icon from './icons'

const ZoomControls: React.FC = () => {
    const zoomLevel = useStageState().zoomLevel ?? 100
    const fitZoomLevel = useStageState().fitZoomLevel ?? 100

    const menu = [
        {
            label: 'Zoom in',
            keyCommand: '⌘+',
            action: () => handleZoomIn(),
        },
        {
            label: 'Zoom out',
            keyCommand: '⌘-',
            action: () => handleZoomOut(),
        },
        {
            label: 'Zoom to 100%',
            keyCommand: '⌘0',
            action: () => handleZoom100(),
        },
        {
            label: 'Zoom to Fit',
            keyCommand: '⌘1',
            action: () => handleZoomFit(),
        },
    ]

    const handleZoomIn = useCallback(() => {
        if (zoomLevel >= 300) {
            return
        }

        setStage({zoomLevel: Math.floor(zoomLevel / 5) * 5 + 5})
    }, [zoomLevel])

    const handleZoomOut = useCallback(() => {
        if (zoomLevel <= 5) {
            return
        }

        setStage({zoomLevel: Math.ceil(zoomLevel / 5) * 5 - 5})
    }, [zoomLevel])

    const handleZoom100 = useCallback(() => {
        setStage({zoomLevel: 100})
    }, [])

    const handleZoomFit = useCallback(() => {
        setStage({zoomLevel: fitZoomLevel})
    }, [fitZoomLevel])

    const hotKeyHandlers = {
        zoom_in: (event: any) => {
            event.preventDefault()
            handleZoomIn()
        },
        zoom_out: (event: any) => {
            event.preventDefault()
            handleZoomOut()
        },
        zoom_fit: (event: any) => {
            event.preventDefault()
            handleZoomFit()
        },
        zoom_100: (event: any) => {
            event.preventDefault()
            handleZoom100()
        },
    }

    return (
        <GlobalHotKeys handlers={hotKeyHandlers} allowChanges={true}>
            <PopOverMenu
                contents={menu}
                width={180}
                placement="top-end"
                autoPlacement={false}
            >
                <Controls>
                    <Stack gap={8} align="center">
                        <ZoomOutButton
                            whileTap={{scale: 0.8}}
                            onClick={(event) => {
                                event.stopPropagation()
                                handleZoomOut()
                            }}
                        >
                            <StyledIcon name="Minus"/>
                        </ZoomOutButton>

                        <ZoomLevel>{zoomLevel}%</ZoomLevel>

                        <ZoomInButton
                            whileTap={{scale: 1.2}}
                            onClick={(event) => {
                                event.stopPropagation()
                                handleZoomIn()
                            }}
                        >
                            <StyledIcon name="Plus"/>
                        </ZoomInButton>
                    </Stack>
                </Controls>
            </PopOverMenu>
        </GlobalHotKeys>
    )
}

const Controls = styled.div`
  position: absolute;
  bottom: 16px;
  right: 0;
  background-color: ${({theme}) => theme.color.glass};
  backdrop-filter: blur(10px);
  border-radius: 8px;
  width: auto;
  transition: all 0.2s ease-in-out;
  ${({theme}) => theme.type.UIRegular}
  ${({theme}) => theme.elevation.depth1}
  color: ${({theme}) => theme.color.text2};
  &:hover,
  &:focus {
    color: ${({theme}) => theme.color.text1};
  }
`

const ZoomButton = styled(motion.button)`
  padding: 8px;
  outline: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover svg,
  &:focus svg {
    fill: ${({theme}) => theme.color.text1};
  }
`

const ZoomOutButton = styled(ZoomButton)`
  padding-right: 0px;
`

const ZoomInButton = styled(ZoomButton)`
  padding-left: 0px;
`

const StyledIcon = styled(Icon)`
  fill: ${({theme}) => theme.color.text2};
  transition: all 0.2s ease-in-out;
  font-size: 16px;
`

const ZoomLevel = styled.div`
  text-align: center;
  min-width: 32px;
  cursor: pointer;
`

export default ZoomControls
