import Device from '../entities/Device'
import DeviceVariant from '../entities/DeviceVariant'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { Container, Graphics, Sprite, Texture } from 'pixi.js'
import Node from './Node'
import INode from './INode'
import cropDesignByDeviceVariant from '../renderers/DesignCropper'

enum DeviceOrientation {
  Portrait,
  Landscape
}

enum SpriteNames {
  Device = 'device',
  Design = 'design',
  Result = 'result'
}

interface IDeviceNodeAttributes {
  deviceOrientation?: DeviceOrientation,
  position: { x: number, y: number },
  designCropValues: IDesignCropValues,

  device?: Device,
  variant?: DeviceVariant,
  originalDesignFileContents?: string,
  designFileContents?: string,

  deviceFileIsLoading: boolean
}

export interface IDesignCropValues {
  x: number,
  y: number,
  width?: number,
  height?: number,
  basedOnFullSizeImage?: boolean
}

export interface IDeviceNodeProps {
  device?: Device,
  variant?: DeviceVariant,
  originalDesignFileContents?: string,
  designFileContents?: string
}

const defaultAttributes: IDeviceNodeAttributes = {
  deviceOrientation: DeviceOrientation.Portrait,
  position: { x: 0, y: 0 },
  deviceFileIsLoading: true,
  designCropValues: { x: 0, y: 0 },
}

export default class DeviceNode extends Node implements INode {
  public attributes: IDeviceNodeAttributes

  private hiddenRenderObject: Container

  private renderer?: PIXI.Application

  constructor(props: IDeviceNodeProps) {
    super()

    this.attributes = {
      ...props,
      ...defaultAttributes,
    }

    this.hiddenRenderObject = new PIXI.Container()
    this.hiddenRenderObject.name = SpriteNames.Result
  }

  resetCropValues(): void {
    this.attributes.designCropValues = defaultAttributes.designCropValues

    //Make default crop based on current variant
    if (this.attributes.originalDesignFileContents === undefined || this.attributes.variant === undefined || this.attributes.deviceOrientation === undefined) {
      return
    }

    const cropResult = cropDesignByDeviceVariant(this.attributes.originalDesignFileContents, this.attributes.variant, this.attributes.deviceOrientation)

    if (cropResult === null) {
      return
    }

    this.attributes.designFileContents = cropResult?.image
    this.attributes.designCropValues = cropResult.cropValues
  }

  setLoadingState(isLoading: boolean): void {
    this.attributes.deviceFileIsLoading = isLoading
  }

  destroy(canvas: Viewport): void {
    canvas.getChildByName(this.id).destroy()
  }

  render(canvas: Viewport, renderer: PIXI.Application): Promise<void> {

    this.hiddenRenderObject = new PIXI.Container()
    this.hiddenRenderObject.name = SpriteNames.Result
    this.renderer = renderer
    return new Promise((resolve, reject) => {
      this.renderDevice().then(() => {
        this.renderDesign(renderer).then(() => {
          //Update loading state
          this.attributes.deviceFileIsLoading = false

          const renderedTexture = PIXI.RenderTexture.create({
            width: this.hiddenRenderObject.width,
            height: this.hiddenRenderObject.height,
          })
          const renderedSprite = new PIXI.Sprite(renderedTexture)
          renderedSprite.name = SpriteNames.Result
          renderedSprite.anchor.set(.5)
          renderedSprite.setTransform(0, 0, 0, 0, this.attributes.deviceOrientation === DeviceOrientation.Landscape ? (Math.PI * 2 * 0.25) : 0)

          const existingNode = canvas.getChildByName(SpriteNames.Result)
          if (existingNode && existingNode instanceof Container) {
            //todo can this be more efficient?
            existingNode.destroy()
          }

          canvas.addChild(renderedSprite)

          renderer.renderer.render(this.hiddenRenderObject, { renderTexture: renderedTexture })

          this.renderObject = renderedSprite
          return resolve()
        })
      }).catch((e) => {
        console.log('render failed', e)
      })
    })

  }

  renderDevice(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.attributes.variant === undefined) {
        return reject()
      }

      if (this.attributes.device === undefined) {
        return reject()
      }

      const mockStageFilePath = this.attributes.variant.getMockStageFilePath()

      let deviceGraphic = this.hiddenRenderObject.getChildByName(SpriteNames.Device)

      if (deviceGraphic === null) {
        deviceGraphic = new PIXI.Sprite()
        deviceGraphic.name = SpriteNames.Device
        this.hiddenRenderObject.addChild(deviceGraphic)
      }

      PIXI.Texture.fromURL(mockStageFilePath).then((deviceTexture: Texture) => {

        if (deviceGraphic instanceof Sprite) {
          deviceGraphic.texture = deviceTexture
        }

        deviceGraphic.name = SpriteNames.Device
        this.hiddenRenderObject.addChild(deviceGraphic)

        return resolve()
      })
    })
  }

  private renderDesign(renderer: PIXI.Application): Promise<void> {
    return new Promise((resolve, reject) => {
      const existingDesignSprite = this.hiddenRenderObject.getChildByName(SpriteNames.Design)

      if (existingDesignSprite !== null) {
        existingDesignSprite.destroy()
      }
      if (this.attributes.designFileContents === undefined) {
        return resolve()
      }

      this.prepareDesignSprite(this.attributes.designFileContents).then((designSprite) => {
        if (designSprite === undefined) {
          return resolve()
        }
        this.getPreparedMaskSprite().then((maskObject) => {
          const deviceSprite = this.hiddenRenderObject.getChildByName(SpriteNames.Device)


          if (designSprite.width < maskObject.width) {
            let scaleFactor = designSprite.width / maskObject.width

            if (this.attributes.deviceOrientation === DeviceOrientation.Landscape) {
              scaleFactor = designSprite.height / maskObject.height
            }

            if (deviceSprite) {
              deviceSprite.scale.set(scaleFactor)
              maskObject.scale.set(scaleFactor)
            }
          }

          //Scale design down if it exceed the mask object dimensions
          else if (designSprite.width > maskObject.width) {
            let scaleFactor = maskObject.width / designSprite.width
            let maskScaleFactor = designSprite.width / maskObject.width

            if (this.attributes.deviceOrientation === DeviceOrientation.Landscape) {
              scaleFactor = maskObject.height / designSprite.height
              maskScaleFactor = designSprite.height / maskObject.height
            }

            designSprite.scale.set(scaleFactor)
            maskObject.scale.set(maskScaleFactor)
          }

          //Apply mask
          designSprite.mask = maskObject
          designSprite.addChild(maskObject)

          /*
          * Use a separate render object to get the complete masked design, so the extract plugin doesn't have to handle the fancy masking-stuff (which it can't..)
          * See also https://stackoverflow.com/questions/47073985/how-and-when-to-use-pixi-rendertexture
          */
          let renderWidth = maskObject.width * (designSprite.width / maskObject.width)
          let renderHeight = maskObject.height * (designSprite.width / maskObject.width)

          if (this.attributes.deviceOrientation === DeviceOrientation.Landscape) {
            renderWidth = maskObject.width * (designSprite.height / maskObject.height)
            renderHeight = maskObject.height * (designSprite.height / maskObject.height)
          }

          const renderedTexture = PIXI.RenderTexture.create({
            width: renderWidth,
            height: renderHeight,
          })
          const renderedSprite = new PIXI.Sprite(renderedTexture)

          //Set correct position, based on selected device variant
          renderedSprite.x += deviceSprite.scale.x * (this.attributes.variant?.getDesignPositions().x ?? 0)
          renderedSprite.y += deviceSprite.scale.y * (this.attributes.variant?.getDesignPositions().y ?? 0)

          renderedSprite.roundPixels = true
          renderedSprite.zIndex = -1

          //Add name for referencing
          renderedSprite.name = SpriteNames.Design
          renderedSprite.roundPixels = true

          this.hiddenRenderObject.addChild(renderedSprite)

          renderer.renderer.render(designSprite, { renderTexture: renderedTexture })

          //Sort objects, so design is in background and device is on top
          this.hiddenRenderObject.sortChildren()

          return resolve()
        })
      })
    })
  }

  /**
   * Return mask graphic, based on current variant mask file or generate a rectangle as mask object
   * @private
   */
  private async getMaskGraphic(): Promise<Sprite | Graphics> {
    return new Promise<PIXI.Sprite | PIXI.Graphics>((resolve) => {

      const variantMaskFile = this.attributes.variant?.getMaskFile()

      if (variantMaskFile) {
        PIXI.Texture.fromURL(variantMaskFile).then(() => {
          resolve(PIXI.Sprite.from(variantMaskFile))
        })

      }

      if (variantMaskFile === undefined) {
        const rectangle = new PIXI.Graphics()
        rectangle.beginFill(0xffffff)
        rectangle.drawRect(
          0,
          0,
          (this.attributes.variant?.getDesignPositions().width ?? 0),
          (this.attributes.variant?.getDesignPositions().height ?? 0),
        )

        resolve(rectangle)
      }
    })

  }

  private prepareDesignSprite(designFileContents: string): Promise<Sprite | undefined> {
    return new Promise<PIXI.Sprite | undefined>((resolve) => {
      if (designFileContents === undefined) {
        return resolve(undefined)
      }
      PIXI.Texture.fromURL(designFileContents).then((imageTexture) => {
        const tempDesignSprite = PIXI.Sprite.from(imageTexture)
        tempDesignSprite.setTransform(
          0,
          (this.attributes.deviceOrientation === DeviceOrientation.Landscape ? tempDesignSprite.width : 0),
          1,
          1,
          this.attributes.deviceOrientation === DeviceOrientation.Landscape ? -(Math.PI * 2 * 0.25) : 0)

        const renderedTexture = PIXI.RenderTexture.create({
          //Scale to correct dimensions. Based on width because scale is based on width of the maskobject
          width: this.attributes.deviceOrientation === DeviceOrientation.Landscape ? tempDesignSprite.height : tempDesignSprite.width,
          height: this.attributes.deviceOrientation === DeviceOrientation.Landscape ? tempDesignSprite.width : tempDesignSprite.height,
        })
        this.renderer && this.renderer.renderer.render(tempDesignSprite, { renderTexture: renderedTexture })
        return resolve(PIXI.Sprite.from(renderedTexture))

      })
    })

  }

  private getPreparedMaskSprite(): Promise<Sprite | Graphics> {
    return new Promise<PIXI.Sprite | PIXI.Graphics>((resolve) => {
      this.getMaskGraphic().then((maskObject) => {
        if (this.attributes.deviceOrientation === DeviceOrientation.Landscape) {
          maskObject.setTransform(
            0,
            0,
            maskObject.scale.x,
            maskObject.scale.y,
            0,
          )
        }

        const renderedTexture = PIXI.RenderTexture.create({
          width: maskObject.width,
          height: maskObject.height,
        })
        this.renderer && this.renderer.renderer.render(maskObject, { renderTexture: renderedTexture })

        return resolve(PIXI.Sprite.from(renderedTexture))
      })

    })

  }

}
