
    
    import { default as AppleProDisplay } from './AppleProDisplay'
    
    import { default as AppleWatch6 } from './AppleWatch6'
    
    import { default as AppleWatchUltra } from './AppleWatchUltra'
    
    import { default as Checkmark } from './Checkmark'
    
    import { default as Close } from './Close'
    
    import { default as Crop } from './Crop'
    
    import { default as HuaweiP8 } from './HuaweiP8'
    
    import { default as IMac } from './IMac'
    
    import { default as IPad } from './IPad'
    
    import { default as IPadPro } from './IPadPro'
    
    import { default as IPhone14 } from './IPhone14'
    
    import { default as IPhone14Concept } from './IPhone14Concept'
    
    import { default as IPhone58 } from './IPhone58'
    
    import { default as IPhoneX111212Mini } from './IPhoneX111212Mini'
    
    import { default as Imac24Inch } from './Imac24Inch'
    
    import { default as Landscape } from './Landscape'
    
    import { default as Loading } from './Loading'
    
    import { default as MacbookPro } from './MacbookPro'
    
    import { default as Menu } from './Menu'
    
    import { default as Minus } from './Minus'
    
    import { default as Moto360 } from './Moto360'
    
    import { default as Pixel4 } from './Pixel4'
    
    import { default as Pixel5 } from './Pixel5'
    
    import { default as Plus } from './Plus'
    
    import { default as Portrait } from './Portrait'
    
    import { default as QuestionMark } from './QuestionMark'
    
    import { default as SamsungFrame } from './SamsungFrame'
    
    import { default as SamsungGalaxyS20 } from './SamsungGalaxyS20'
    
    import { default as Select } from './Select'
    

    import iconIndex from './iconIndex'

    import { IconType, IconNames } from './template/IconType'
    import { SVGProps } from 'react'

    type Props = IconType & SVGProps<SVGSVGElement> & {
      name: IconNames,
      title?: string
    };

    const Icon: React.FC<Props> = ({name, title, ...restProps}) => {

      const icons = [AppleProDisplay,AppleWatch6,AppleWatchUltra,Checkmark,Close,Crop,HuaweiP8,IMac,IPad,IPadPro,IPhone14,IPhone14Concept,IPhone58,IPhoneX111212Mini,Imac24Inch,Landscape,Loading,MacbookPro,Menu,Minus,Moto360,Pixel4,Pixel5,Plus,Portrait,QuestionMark,SamsungFrame,SamsungGalaxyS20,Select]
      const indexOfIcon = iconIndex.findIndex((element) => element === name);
      const IconComponent: any = icons[indexOfIcon]

      return IconComponent ? <IconComponent {...restProps} /> : null

    }
    export default Icon
    